export const resumeNome = (nomeCompleto: String | undefined) => {
  if (!nomeCompleto) {
    return '';
  }

  const ligacoes = new Set(['de', 'da', 'das', 'do', 'dos', 'e']);

  const nomes = nomeCompleto
    .trim()
    .split(/\s+/)
    .filter(nome => !ligacoes.has(nome.toLowerCase()));

  if (nomes.length > 1) {
    return `${nomes[0]} ${nomes[1]}`;
  }

  return nomes[0] || '';
};
