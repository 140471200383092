import React, { useEffect, useCallback, useState } from 'react';
import { FiXCircle } from 'react-icons/fi';

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogMessage,
  DialogTitle,
  Overlay,
  InputObs,
  CloseButton,
} from './styles';

import IListaEspera from '../../routes/vms/IListaEspera';

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  exigeMotivo: boolean;
  textoConfirmar?: string;
  textoCancelar?: string;
  esperaParametro?: IListaEspera;
  onConfirm: (motivo: string) => void;
  onCancel: () => void;
  onClose?: () => void;
}

const ConfirmationModal: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  exigeMotivo,
  textoCancelar,
  textoConfirmar,
  onConfirm,
  onCancel,
  onClose,
}) => {
  const [motivo, setMotivo] = useState('');

  const aoAlterarMotivo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMotivo(event.target.value);
    },
    [],
  );

  useEffect(() => {
    if (open) {
      setMotivo('');
    }
  }, [open]);

  return (
    <Overlay open={open}>
      <Dialog>
        {!exigeMotivo && (
          <CloseButton onClick={onClose}>
            <FiXCircle />
          </CloseButton>
        )}
        <DialogTitle>{title}</DialogTitle>
        <DialogMessage>{message}</DialogMessage>
        {exigeMotivo && (
          <InputObs
            variant="outlined"
            margin="normal"
            id="motivo"
            label="Motivo"
            name="motivo"
            value={motivo}
            onChange={aoAlterarMotivo}
          />
        )}
        <ButtonGroup>
          <Button
            variant={textoCancelar ? 'default' : 'cancel'}
            onClick={onCancel}
          >
            {textoCancelar ? textoCancelar : 'Cancelar'}
          </Button>
          <Button
            variant={textoConfirmar ? 'default' : 'confirm'}
            onClick={() => {
              onConfirm(motivo);
            }}
          >
            {textoConfirmar ? textoConfirmar : 'Confirmar'}
          </Button>
        </ButtonGroup>
      </Dialog>
    </Overlay>
  );
};

export default ConfirmationModal;
