import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  BotaoExcluir,
  BotaoSalvar,
  BotoesContainer,
} from './styles';
import { FiSave, FiTrash2 } from 'react-icons/fi';

import api from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';
import { useConfigs } from '../../context/ConfigsContext';
import { returnAxiosError } from '../../utils/returnAxiosError';

import ComboboxComFiltro from '../../components/autocomplete';

import ICategoria from '../../routes/vms/ICategoria';
import IProfissional from '../../routes/vms/IProfissional';
import IAgendamento from '../../routes/vms/IAgendamento';

interface HorariosCombo {
  id: string;
  nome: string;
}

interface BloqueioProps {
  onClose: () => void;
  bloqueioParametro: IAgendamento | undefined;
  profissionalParametro: string;
  dataParametro: Date;
  horaParametro: string;
}

const Bloqueio: React.FC<BloqueioProps> = ({
  bloqueioParametro,
  profissionalParametro,
  dataParametro,
  horaParametro,
  onClose,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { lerConfig } = useConfigs();

  function adicionarIntervalo(horario: string) {
    let [horas, minutos] = horario.split(':').map(Number);
    let data = new Date();

    const intervalo = Number(lerConfig('INTERVALO'));

    data.setHours(horas, minutos, 0);
    data.setMinutes(data.getMinutes() + intervalo);

    return data.toTimeString().slice(0, 5);
  }

  const [profissional, setProfissional] = useState<string | null>(
    profissionalParametro,
  );
  const [profissionalErro, setProfissionalErro] = useState<string>('');
  const [profissionais, setProfissionais] = useState<IProfissional[]>([]);

  const [categoria, setCategoria] = useState<string | null>(null);
  const [categoriaErro, setCategoriaErro] = useState<string>('');
  const [categorias, setCategorias] = useState<ICategoria[]>([]);

  const [horaIni, setHoraIni] = useState<string | null>(horaParametro);
  const [horaIniErro, setHoraIniErro] = useState<string>('');

  const [horaFim, setHoraFim] = useState<string | null>(
    adicionarIntervalo(horaParametro),
  );
  const [horaFimErro, setHoraFimErro] = useState<string>('');

  const [horarios, setHorarios] = useState<HorariosCombo[]>([]);

  const [bloqueado, setBloqueado] = useState(false);

  const atualizaDados = useCallback(async () => {
    try {
      const [categoriasResponse, profissionaisResponse] = await Promise.all([
        api.get<ICategoria[]>('/categoria/lista', {
          params: { inutilizar: true },
        }),
        api.get<IProfissional[]>(
          `/users/profissionais?empresa_id=${user.empresa_id}`,
        ),
      ]);

      setCategorias(categoriasResponse.data);
      setProfissionais(profissionaisResponse.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }, [user.empresa_id]);

  const aoAlterarProfissional = (novoProfissional: string | null) => {
    setProfissional(novoProfissional);
    setProfissionalErro('');
  };

  const aoAlterarCategoria = (novaCategoria: string | null) => {
    setCategoria(novaCategoria);
    setCategoriaErro('');
  };

  const aoAlterarHoraIni = (novoHorario: string | null) => {
    setHoraIni(novoHorario);
    setHoraIniErro('');
  };

  const aoAlterarHoraFim = (novoHorario: string | null) => {
    setHoraFim(novoHorario);
    setHoraFimErro('');
  };

  const validacoesAgendamento = useCallback(() => {
    setProfissionalErro('');
    setHoraIniErro('');
    setHoraFimErro('');
    setCategoriaErro('');

    let erroValidacao = false;
    if (!profissional) {
      setProfissionalErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (!horaIni) {
      setHoraIniErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (!horaFim) {
      setHoraFimErro('Informação Obrigatória!');
      erroValidacao = true;
    }

    if (!categoria) {
      setCategoriaErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação',
        description:
          'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return false;
    }
  }, [addToast, profissional, horaIni, horaFim, categoria]);

  const defineHorarios = useCallback(async () => {
    const horariosArray = await api.get<string[]>(
      `/empresas/horarios/${user.empresa.url_frontend}`,
    );

    const horariosCombo: HorariosCombo[] = horariosArray.data.map(horario => ({
      id: horario,
      nome: horario,
    }));

    setHorarios(horariosCombo);
  }, [user]);

  const handleExcluirAgendamento = useCallback(async () => {
    if (!bloqueioParametro) {
      addToast({
        type: 'error',
        title: 'Erro ao excluir',
        description: 'Nenhum horário inativo selecionado para exclusão',
      });

      return;
    }

    try {
      await api.delete(`/agendamentos/inutilizado/`, {
        params: { agendamento_id: bloqueioParametro.id },
      });
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }

    addToast({
      type: 'success',
      title: 'Removido',
      description: 'Horári inutilizado excluido',
    });

    onClose();
  }, [addToast, bloqueioParametro, onClose]);

  const aoSalvar = useCallback(async () => {
    if (validacoesAgendamento() === false) {
      return;
    }

    let ano;
    let mes;
    let dia;

    let dataFormatada;

    ano = dataParametro.getFullYear();
    mes = String(dataParametro.getMonth() + 1).padStart(2, '0'); // getMonth() retorna de 0 a 11
    dia = String(dataParametro.getDate()).padStart(2, '0');
    dataFormatada = `${ano}-${mes}-${dia}`;

    const bloqueioGravar = {
      horaIni,
      horaFim,
      categoria_id: categoria,
      profissional_id: profissional ? profissional : '',
      data_criacao: dataFormatada ?? '',
    };

    try {
      await api.post('/agendamentos/inutilizar', bloqueioGravar);
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }

    addToast({
      type: 'success',
      title: 'Parabéns',
      description: 'Horário inutilizado com sucesso',
    });

    onClose();
  }, [
    addToast,
    categoria,
    dataParametro,
    horaIni,
    horaFim,
    profissional,
    validacoesAgendamento,
    onClose,
  ]);

  useEffect(() => {
    defineHorarios();
  }, [defineHorarios]);

  useEffect(() => {
    atualizaDados();
  }, [atualizaDados]);

  useEffect(() => {
    if (bloqueioParametro) {
      setCategoria(
        bloqueioParametro.categoria ? bloqueioParametro.categoria.id : '',
      );
      setBloqueado(true);
    }
  }, [bloqueioParametro]);

  return (
    <Container>
      <h1>Bloqueio</h1>

      <span>
        <ComboboxComFiltro
          opcoes={profissionais}
          value={profissional}
          label="Profissional"
          onChange={aoAlterarProfissional}
          error={profissionalErro !== ''}
          helperText={profissionalErro}
          size={5}
          temMargin={true}
          disabled={bloqueado}
        />

        <ComboboxComFiltro
          opcoes={horarios}
          value={horaIni}
          label="Hora Início"
          onChange={aoAlterarHoraIni}
          error={horaIniErro !== ''}
          helperText={horaIniErro}
          size={1.7}
          temMargin={true}
          disabled={bloqueado}
        />

        <ComboboxComFiltro
          opcoes={horarios}
          value={horaFim}
          label="Hora Fim"
          onChange={aoAlterarHoraFim}
          error={horaFimErro !== ''}
          helperText={horaFimErro}
          size={1.7}
          disabled={bloqueado}
        />
      </span>

      <ComboboxComFiltro
        opcoes={categorias}
        value={categoria}
        label="Categoria"
        onChange={aoAlterarCategoria}
        error={categoriaErro !== ''}
        helperText={categoriaErro}
        disabled={bloqueado}
      />

      <BotoesContainer>
        {!bloqueioParametro && (
          <BotaoSalvar onClick={aoSalvar}>
            <FiSave />
            Salvar
          </BotaoSalvar>
        )}

        <BotaoExcluir onClick={() => handleExcluirAgendamento()}>
          <FiTrash2 />
          Excluir
        </BotaoExcluir>
      </BotoesContainer>
    </Container>
  );
};

export default Bloqueio;
