import styled, { css, keyframes } from 'styled-components';
import { shade, tint } from 'polished';
import { TextField } from '@material-ui/core';

interface ToggleProps {
  inutilizado: boolean;
}

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'firefox';
  } else if (userAgent.includes('Safari')) {
    return 'safari';
  } else {
    return 'other'; // Para outros navegadores
  }
};

const getScrollbarStyles = (browser: string) => {
  if (browser !== 'firefox') {
    return css`
      /* Estilizando o Scrollbar para Chrome/Safari */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px; /* Para barras de rolagem horizontais */
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color: ${props =>
          props.theme.secundaria}; /* Cor do "thumb" */
        border-radius: 10px;
        border: 3px solid transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Cor do "track" */
      }

      /* Remove as setas do scrollbar em WebKit */
      &::-webkit-scrollbar-button {
        display: none; /* Remove as setas do scrollbar */
      }
    `;
  } else {
    return css`
      /* Estilizando o Scrollbar para Firefox */
      scrollbar-width: thin; /* Largura do scrollbar: "auto" ou "thin" */
      scrollbar-color: ${props => props.theme.secundaria} #f0f0f0; /* Cor do thumb e do track */
    `;
  }
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  animation: ${fadeIn} 2s;

  height: 100vh;
  overflow: hidden;

  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.theme.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.theme.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.theme.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.theme.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.theme.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.theme.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.theme.textoClaro};
    }
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;

  button:first-of-type {
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    gap: 11px;

    button:first-of-type {
      margin-top: 14px;
    }
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;

    flex: 1;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const SalvarButton = styled.button`
  padding: 8px;
  background: ${props => props.theme.secundaria};
  color: #fff;

  border: none;
  border-radius: 8px;

  font-weight: bold;
  font-size: 16px;

  height: 58px;
  width: 110px;

  @media (max-width: 610px) {
    width: 60px;
    font-size: 14px;
  }

  &:hover {
    background: ${props => props.theme.secundariaShade};
  }
`;

export const InputNome = styled(TextField)`
  flex: 1;

  @media (min-width: 768px) {
    margin-right: 8px !important;
  }
`;

export const Pesquisa = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  height: 80%;
  overflow-y: auto;
  ${getScrollbarStyles(detectBrowser())};
`;

export const CardCategoria = styled.div<{ cor: string }>`
  animation: ${fadeIn} 2s;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  margin: 0 5px 10px 0;

  cursor: pointer;

  padding: 0px 15px;
  height: 75px;
  background-color: ${props => tint(0.8, props.cor)};
  border-left: ${props => `5px solid ${props.cor}`};

  &:hover {
    background-color: ${props => tint(0.5, props.cor)};
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  svg {
    height: 20px;
    width: 20px;

    &:hover {
      color: ${props => shade(0.5, props.cor)};
    }
  }
`;

export const GroupBox = styled.fieldset`
  border: 1px solid ${props => props.theme.secundaria} !important;
  border-radius: 10px;
  padding: 0 20px 15px !important;

  height: 100%;
`;

export const GroupBoxLegenda = styled.legend`
  font-size: 20px !important;
  /* font-weight: bold !important; */
  text-align: left !important;
  color: ${props => props.theme.secundaria};
  width: auto;
  padding: 0px 5px;
  border-bottom: none;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px; /* Ajuste para acomodar o texto */
  height: 28px;
  background: white;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  border: 2px solid ${props => props.theme.secundaria};
  transition: all 0.4s ease;
  margin-top: 8px;
`;

export const ToggleCircle = styled.div<ToggleProps>`
  width: 28px;
  height: 28px;
  background: ${props => props.theme.secundaria};
  border-radius: 50%;
  position: absolute;
  left: ${props => (props.inutilizado ? '0px' : '78px')}; /* Movimento suave */
  transition: all 0.5s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Adiciona sombra ao círculo */
`;

export const ToggleText = styled.span<ToggleProps>`
  color: ${props => props.theme.secundaria};
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  left: ${props =>
    props.inutilizado ? '32px' : '20px'}; /* Posição dinâmica */
  transition: all 0.4s ease;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;
    margin-top: 5px;

    input {
      appearance: none;
      width: 16px;
      height: 16px;
      border: 2px solid ${props => props.theme.secundaria};
      border-radius: 4px;
      background-color: white;
      cursor: pointer;
      transition: background-color 0.3s, border-color 0.3s;

      margin-right: 5px;

      :checked {
        background-color: ${props => props.theme.secundaria};
        border-color: ${props => props.theme.secundaria};
      }

      :checked::after {
        content: '✔';
        color: white;
        font-size: 12px;
        text-align: center;
        display: block;
        position: relative;
        top: -2px;
      }
    }
  }
`;
