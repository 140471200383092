import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  ContainerButton,
  AddPacienteButton,
  InputObs,
  BotaoExcluir,
  BotaoLimpar,
  BotaoSalvar,
  BotoesContainer,
  InputData,
} from './styles';
import { FiPlus, FiSave, FiRefreshCw, FiTrash2 } from 'react-icons/fi';

import api from '../../services/api';
import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';
import { useIndexedDB } from '../../context/IndexedDBContext';
import { useConfigs } from '../../context/ConfigsContext';
import { returnAxiosError } from '../../utils/returnAxiosError';

import ComboboxComFiltro from '../../components/autocomplete';
import Dialog from '../../components/dialog';
import Paciente from '../Paciente';
import ConfirmationModal from '../../components/confirmationModal';

import IPaciente from '../../routes/vms/IPaciente';
import ICategoria from '../../routes/vms/ICategoria';
import IProfissional from '../../routes/vms/IProfissional';
import IAgendamento from '../../routes/vms/IAgendamento';
import IListaEspera from '../../routes/vms/IListaEspera';

interface HorariosCombo {
  id: string;
  nome: string;
}

interface AgendamentoProps {
  onClose: () => void;
  agendamentoParametro: IAgendamento | undefined;
  esperaParametro?: IListaEspera;
  profissionalParametro: string;
  dataParametro: Date;
  horaParametro: string;
}

const Agendamento: React.FC<AgendamentoProps> = ({
  agendamentoParametro,
  esperaParametro = undefined,
  profissionalParametro,
  dataParametro,
  horaParametro,
  onClose,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { lerIndexedDB, excluirIndexedDB } = useIndexedDB();
  const { lerConfig } = useConfigs();

  function adicionarIntervalo(horario: string) {
    let [horas, minutos] = horario.split(':').map(Number);
    let data = new Date();

    const intervalo = Number(lerConfig('INTERVALO'));

    data.setHours(horas, minutos, 0);
    data.setMinutes(data.getMinutes() + intervalo);

    return data.toTimeString().slice(0, 5);
  }

  const [paciente, setPaciente] = useState<string | null>(null);
  const [pacienteErro, setPacienteErro] = useState<string>('');
  const [pacientes, setPacientes] = useState<IPaciente[]>([]);

  const [profissional, setProfissional] = useState<string | null>(
    profissionalParametro,
  );
  const [profissionalErro, setProfissionalErro] = useState<string>('');
  const [profissionais, setProfissionais] = useState<IProfissional[]>([]);

  const [data, setData] = useState('');
  const [dataErro, setDataErro] = useState('');

  const [categoria, setCategoria] = useState<string | null>(null);
  const [categoriaErro, setCategoriaErro] = useState<string>('');
  const [categorias, setCategorias] = useState<ICategoria[]>([]);

  const [horaIni, setHoraIni] = useState<string | null>(horaParametro);
  const [horaIniErro, setHoraIniErro] = useState<string>('');

  const [horaFim, setHoraFim] = useState<string | null>(
    adicionarIntervalo(horaParametro),
  );
  const [horaFimErro, setHoraFimErro] = useState<string>('');

  const [horarios, setHorarios] = useState<HorariosCombo[]>([]);

  const [obs, setObs] = useState<string>('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [agendado, setAgendado] = useState(false);

  const atualizaDados = useCallback(async () => {
    try {
      const [categoriasResponse, pacientesResponse, profissionaisResponse] =
        await Promise.all([
          api.get<ICategoria[]>('/categoria/lista'),
          api.get<IPaciente[]>('/pacientes/'),
          api.get<IProfissional[]>(
            `/users/profissionais?empresa_id=${user.empresa_id}`,
          ),
        ]);

      setCategorias(categoriasResponse.data);
      setPacientes(pacientesResponse.data);
      setProfissionais(profissionaisResponse.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }, [user.empresa_id]);

  const handleOpenDialog = () => setDialogOpen(true);

  const handleCloseDialog = useCallback(async () => {
    setDialogOpen(false);

    lerIndexedDB<string>(
      `${user.empresa.url_frontend}:id-paciente-gravado`,
    ).then(idIndexedDB => {
      if (idIndexedDB !== null) {
        setPaciente(String(idIndexedDB));
        atualizaDados();

        excluirIndexedDB(`${user.empresa.url_frontend}:id-paciente-gravado`);
      }
    });
  }, [atualizaDados, excluirIndexedDB, lerIndexedDB, user]);

  const aoAlterarPaciente = (novoPaciente: string | null) => {
    setPaciente(novoPaciente);
    setPacienteErro('');
  };

  const aoAlterarData = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(event.target.value);
    setDataErro('');
  };

  const aoAlterarProfissional = (novoProfissional: string | null) => {
    setProfissional(novoProfissional);
    setProfissionalErro('');
  };

  const aoAlterarCategoria = (novaCategoria: string | null) => {
    setCategoria(novaCategoria);
    setCategoriaErro('');
  };

  const aoAlterarHoraIni = (novoHorario: string | null) => {
    setHoraIni(novoHorario);
    setHoraIniErro('');
  };

  const aoAlterarHoraFim = (novoHorario: string | null) => {
    setHoraFim(novoHorario);
    setHoraFimErro('');
  };

  const aoAlterarObs = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setObs(event.target.value);
    },
    [],
  );

  const validacoesAgendamento = useCallback(() => {
    setProfissionalErro('');
    setHoraIniErro('');
    setHoraFimErro('');
    setPacienteErro('');
    setCategoriaErro('');
    setDataErro('');

    let erroValidacao = false;
    if (!profissional) {
      setProfissionalErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (esperaParametro) {
      if (!data) {
        setDataErro('Informação obrigatória!');
        erroValidacao = true;
      }
    }

    if (!horaIni) {
      setHoraIniErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (!horaFim) {
      setHoraFimErro('Informação Obrigatória!');
      erroValidacao = true;
    }

    if (!paciente) {
      setPacienteErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (!categoria) {
      setCategoriaErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação',
        description:
          'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return false;
    }
  }, [
    addToast,
    profissional,
    horaIni,
    horaFim,
    paciente,
    categoria,
    data,
    esperaParametro,
  ]);

  const defineHorarios = useCallback(async () => {
    const horariosArray = await api.get<string[]>(
      `/empresas/horarios/${user.empresa.url_frontend}`,
    );

    const horariosCombo: HorariosCombo[] = horariosArray.data.map(horario => ({
      id: horario,
      nome: horario,
    }));

    setHorarios(horariosCombo);
  }, [user]);

  const aoLimpar = useCallback(() => {
    setPaciente(null);
    setCategoria(null);
    setObs('');
  }, []);

  const handleExcluirAgendamento = useCallback(
    async (motivo: string) => {
      if (!agendamentoParametro) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir',
          description: 'Nenhum agendamento selecionado para exclusão',
        });

        return;
      }

      if (motivo === '') {
        addToast({
          type: 'error',
          title: 'Erro ao excluir',
          description: 'Motivo não informado',
        });

        return;
      }

      try {
        await api.post(
          `/agendamentos/excluir/${agendamentoParametro.id}/${false}`,
          {
            motivo: motivo.toUpperCase(),
          },
        );
      } catch (error) {
        addToast(returnAxiosError(error));

        return;
      }

      setModalOpen(false);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Agendamento excluido',
      });

      onClose();
    },
    [addToast, agendamentoParametro, onClose],
  );

  const aoSalvar = useCallback(async () => {
    if (validacoesAgendamento() === false) {
      return;
    }

    let ano;
    let mes;
    let dia;

    let dataFormatada;

    if (esperaParametro) {
      const dataISO = new Date(data);
      dataISO.setHours(dataISO.getHours() + 3);

      ano = dataISO.getFullYear();
      mes = String(dataISO.getMonth() + 1).padStart(2, '0'); // getMonth() retorna de 0 a 11
      dia = String(dataISO.getDate()).padStart(2, '0');
      dataFormatada = `${ano}-${mes}-${dia}`;
    } else {
      ano = dataParametro.getFullYear();
      mes = String(dataParametro.getMonth() + 1).padStart(2, '0'); // getMonth() retorna de 0 a 11
      dia = String(dataParametro.getDate()).padStart(2, '0');
      dataFormatada = `${ano}-${mes}-${dia}`;
    }

    const agendamentoGravar: Omit<IAgendamento, 'id' | 'inutilizado'> = {
      horaIni,
      horaFim,
      categoria_id: categoria,
      paciente_id: paciente,
      profissional_id: profissional ? profissional : '',
      observacoes: obs.toUpperCase(),
      data_criacao: dataFormatada ?? '',
    };

    let agendamentoAlterar;
    if (agendamentoParametro) {
      agendamentoAlterar = {
        categoria_id: categoria,
        hora: horaIni,
        observacoes: obs,
        data_criacao: dataFormatada,
        profissional_id: profissional,
        paciente_id: paciente,
        inutilizado: false,
      };
    }

    try {
      if (agendamentoParametro) {
        await api.put(
          `/agendamentos/${agendamentoParametro.id}`,
          agendamentoAlterar,
        );
      } else {
        await api.post('/agendamentos', agendamentoGravar);

        if (esperaParametro) {
          await api.post(`/espera/excluir/${esperaParametro.id}`, {
            motivo: `PACIENTE AGENDADO NO DIA ${dia}/${mes}/${ano}`,
          });
        }
      }
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }

    addToast({
      type: 'success',
      title: 'Parabéns',
      description: 'Agendamento cadastrado com sucesso',
    });

    onClose();
  }, [
    addToast,
    data,
    categoria,
    dataParametro,
    horaIni,
    horaFim,
    paciente,
    obs,
    profissional,
    agendamentoParametro,
    validacoesAgendamento,
    onClose,
    esperaParametro,
  ]);

  useEffect(() => {
    defineHorarios();
  }, [defineHorarios]);

  useEffect(() => {
    atualizaDados();
  }, [atualizaDados]);

  useEffect(() => {
    if (agendamentoParametro) {
      setPaciente(
        agendamentoParametro.paciente ? agendamentoParametro.paciente.id : '',
      );
      setCategoria(
        agendamentoParametro.categoria ? agendamentoParametro.categoria.id : '',
      );
      setObs(
        agendamentoParametro.observacoes
          ? agendamentoParametro.observacoes
          : '',
      );
      setAgendado(true);
    }
  }, [agendamentoParametro]);

  useEffect(() => {
    if (esperaParametro) {
      setPaciente(esperaParametro.paciente.id);
      setCategoria(esperaParametro.categoria.id);
    }
  }, [esperaParametro]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <Container>
      <h1>{'Agendamento'}</h1>

      <span>
        {esperaParametro && (
          <InputData
            variant="outlined"
            margin="normal"
            id="data"
            label="Data"
            name="data"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={data}
            onChange={aoAlterarData}
            error={dataErro !== ''}
            helperText={dataErro}
          />
        )}

        <ComboboxComFiltro
          opcoes={profissionais}
          value={profissional}
          label="Profissional"
          onChange={aoAlterarProfissional}
          error={profissionalErro !== ''}
          helperText={profissionalErro}
          size={5}
          temMargin={true}
        />

        <ComboboxComFiltro
          opcoes={horarios}
          value={horaIni}
          label="Hora Início"
          onChange={aoAlterarHoraIni}
          error={horaIniErro !== ''}
          helperText={horaIniErro}
          size={1.7}
          temMargin={true}
          disabled={agendado}
        />

        <ComboboxComFiltro
          opcoes={horarios}
          value={horaFim}
          label="Hora Fim"
          onChange={aoAlterarHoraFim}
          error={horaFimErro !== ''}
          helperText={horaFimErro}
          size={1.7}
          disabled={agendado}
        />
      </span>

      <ContainerButton>
        <ComboboxComFiltro
          opcoes={pacientes}
          value={paciente}
          label="Paciente"
          onChange={aoAlterarPaciente}
          error={pacienteErro !== ''}
          helperText={pacienteErro}
          disabled={esperaParametro ? true : false}
        />

        <AddPacienteButton
          title="Cadastrar Paciente"
          onClick={handleOpenDialog}
          disabled={esperaParametro ? true : false}
        >
          <FiPlus />
        </AddPacienteButton>

        <Dialog isOpen={isDialogOpen} onClose={handleCloseDialog}>
          <Paciente externa={true} onClose={handleCloseDialog} />
        </Dialog>
      </ContainerButton>

      <ComboboxComFiltro
        opcoes={categorias}
        value={categoria}
        label="Categoria"
        onChange={aoAlterarCategoria}
        error={categoriaErro !== ''}
        helperText={categoriaErro}
      />

      <InputObs
        variant="outlined"
        margin="normal"
        id="obs"
        label="Observações"
        name="obs"
        value={obs}
        onChange={aoAlterarObs}
      />

      <BotoesContainer>
        <BotaoSalvar onClick={aoSalvar}>
          <FiSave />
          Salvar
        </BotaoSalvar>

        {!esperaParametro && (
          <BotaoLimpar onClick={aoLimpar}>
            <FiRefreshCw />
            Limpar
          </BotaoLimpar>
        )}

        {agendamentoParametro && !esperaParametro && (
          <BotaoExcluir
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <FiTrash2 />
            Excluir
          </BotaoExcluir>
        )}
      </BotoesContainer>

      <ConfirmationModal
        open={isModalOpen}
        title="Confirmar Exclusão"
        message="Informe o motivo para excluir esse agendamento"
        exigeMotivo={true}
        onConfirm={motivo => {
          handleExcluirAgendamento(motivo);
        }}
        onCancel={() => setModalOpen(false)}
      />
    </Container>
  );
};

export default Agendamento;
