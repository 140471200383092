import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    color: ${props => props.theme.secundaria};
  }
`;

export const NotificacoesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 15px;
`;

export const NotificacoesCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60px;

  cursor: default;

  border: 1px solid ${props => props.theme.secundaria};
  border-left: 5px solid ${props => props.theme.secundaria};

  padding: 5px;

  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

    p:first-child {
      font-weight: bold;
    }

    p:nth-child(3) {
      &.negrito {
        font-weight: bold;
      }
    }
  }

  h4 {
    font-weight: bold;
    color: ${props => props.theme.secundaria};

    margin-bottom: 10px;
  }
`;
