import React, { useEffect, useState, useCallback, useMemo } from 'react';

import api from '../../services/api';
import { useToast } from '../../context/ToastContext';
import { returnAxiosError } from '../../utils/returnAxiosError';

import IPerfis from '../../routes/vms/IPerfis';
import IPerfisAcessos from '../../routes/vms/IPerfisAcessos';

import {
  Container,
  ContainerTabs,
  TabButton,
  GroupBox,
  GroupBoxLegenda,
  ContainerButton,
  ContainerPesquisa,
  CardPerfisPesquisa,
  InputPerfil,
  Botao,
  PermissoesContainer,
  CardPermissoes,
  PermissaoText,
  ToggleCircle,
  ToggleContainer,
  ToggleText,
} from './styles';

import {
  FiUserPlus,
  FiFilter,
  FiTrash2,
  FiSave,
  FiRefreshCw,
} from 'react-icons/fi';

const permissoesArray: IPerfisAcessos[] = [
  { acesso: 'AGENDAR', tem_acesso: false },
  {
    acesso: 'AGENDAR A PARTIR DA LISTA DE ESPERA',
    tem_acesso: false,
  },
  {
    acesso: 'AGENDAR ALGUÉM DA LISTA DE ESPERA QUE NÃO SEJA O PRIMEIRO',
    tem_acesso: false,
  },
  {
    acesso: 'ALTERAR ORDEM DA LISTA DE ESPERA',
    tem_acesso: false,
  },
  {
    acesso: 'CANCELAR AGENDAMENTO',
    tem_acesso: false,
  },
  { acesso: 'EXCLUIR DA LISTA DE ESPERA', tem_acesso: false },
  { acesso: 'INUTILIZAR HORÁRIOS DA AGENDA', tem_acesso: false },
  { acesso: 'VISUALIZAR CADASTRO DE PERFIL', tem_acesso: false },
  { acesso: 'VISUALIZAR CADASTRO DO PACIENTE', tem_acesso: false },
  { acesso: 'VISUALIZAR CADASTRO DO USUÁRIO', tem_acesso: false },
  { acesso: 'VISUALIZAR LISTA DE ESPERA', tem_acesso: false },
];

const Perfis: React.FC = () => {
  const { addToast } = useToast();

  const [perfil, setPerfil] = useState<string>('');
  const [perfilErro, setPerfilErro] = useState<string>('');
  const [perfilPesquisa, setPerfilPesquisa] = useState<string>('');

  const [perfilSelecionado, setPerfilSelecionado] = useState<IPerfis>();

  const [permissoes, setPermissoes] =
    useState<IPerfisAcessos[]>(permissoesArray);
  const [tabAtiva, setTabAtiva] = useState('Cadastro');

  const [perfis, setPerfis] = useState<IPerfis[]>([]);
  const [perfisPesquisados, setPerfisPesquisados] = useState<IPerfis[]>([]);

  const atualizaDados = useCallback(async () => {
    try {
      const response = await api.get('/perfis/todos');

      setPerfis(response.data);
      setPerfisPesquisados(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }, []);

  const aoAlterarPerfil = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPerfil(event.target.value);
    },
    [],
  );

  const aoAlterarPerfilPesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPerfilPesquisa(event.target.value);
    },
    [],
  );

  const aoLimpar = useCallback(() => {
    setPerfil('');
    setPerfilSelecionado(undefined);
    setPermissoes(prevPermissoes =>
      prevPermissoes.map(p => ({ ...p, tem_acesso: false })),
    );
  }, []);

  const handleSelecionarPerfil = useCallback((perfil: IPerfis) => {
    setPerfil(perfil.descricao);
    setPerfilSelecionado(perfil);
    setPermissoes(
      perfil.acessos.sort((a, b) => a.acesso.localeCompare(b.acesso)),
    );

    setTabAtiva('Cadastro');
  }, []);

  const handleExcluirPerfil = useCallback(async () => {
    if (!perfilSelecionado) {
      addToast({
        type: 'error',
        title: 'Nenhum perfil selecionado para exclusão, verifique',
      });

      return;
    }

    try {
      await api.delete(`/perfis/${perfilSelecionado.id}`);

      addToast({
        type: 'success',
        title: 'Perfil removido com sucesso',
      });
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }

    atualizaDados();
    aoLimpar();
  }, [addToast, aoLimpar, atualizaDados, perfilSelecionado]);

  const aoSalvar = useCallback(async () => {
    setPerfilErro('');

    if (perfil === '') {
      setPerfilErro('Informação obrigatória!');
      return;
    }

    const perfilGravar: Omit<IPerfis, 'id'> = {
      descricao: perfil.toUpperCase(),
      acessos: permissoes,
    };

    try {
      if (perfilSelecionado) {
        await api.put('perfis', perfilGravar, {
          params: { id: perfilSelecionado.id },
        });
      } else {
        await api.post('perfis', perfilGravar);
      }

      addToast({
        type: 'success',
        title: 'Perfil salvo com sucesso!!!',
      });

      atualizaDados();
      aoLimpar();
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }
  }, [
    addToast,
    aoLimpar,
    atualizaDados,
    perfil,
    perfilSelecionado,
    permissoes,
  ]);

  const togglePermissao = useCallback((permissaoPar: string) => {
    setPermissoes(prevPermissoes =>
      prevPermissoes.map(p =>
        p.acesso === permissaoPar ? { ...p, tem_acesso: !p.tem_acesso } : p,
      ),
    );
  }, []);

  const permissoesMap = useMemo(() => {
    return permissoes.reduce((acc, p) => {
      acc[p.acesso] = p.tem_acesso;
      return acc;
    }, {} as Record<string, boolean>);
  }, [permissoes]);

  const verificaPermissao = (acesso: string) => permissoesMap[acesso] ?? false;

  useEffect(() => {
    let listaFiltrar = perfis;

    if (perfilPesquisa !== '') {
      listaFiltrar = listaFiltrar?.filter(perfil =>
        perfil.descricao.toUpperCase().includes(perfilPesquisa.toUpperCase()),
      );
    }

    setPerfisPesquisados(listaFiltrar);
  }, [perfilPesquisa, perfis]);

  useEffect(() => {
    atualizaDados();
  }, [atualizaDados]);

  return (
    <Container>
      <ContainerTabs>
        <TabButton
          onClick={() => {
            setTabAtiva('Cadastro');
          }}
          ativa={tabAtiva === 'Cadastro'}
        >
          <FiUserPlus />
        </TabButton>
        <TabButton
          onClick={() => {
            setTabAtiva('Pesquisa');
          }}
          ativa={tabAtiva === 'Pesquisa'}
        >
          <FiFilter />
        </TabButton>
      </ContainerTabs>

      {tabAtiva === 'Cadastro' && (
        <GroupBox>
          <GroupBoxLegenda>Perfil</GroupBoxLegenda>

          <span>
            <InputPerfil
              variant="outlined"
              margin="normal"
              id="perfil"
              label="Perfil"
              name="perfil"
              value={perfil}
              onChange={aoAlterarPerfil}
              error={perfilErro !== ''}
              helperText={perfilErro}
            />

            <ContainerButton>
              <Botao
                title="Salvar cadastro"
                onClick={() => {
                  aoSalvar();
                }}
              >
                <FiSave />
              </Botao>

              <Botao
                title="Limpar cadastro"
                onClick={() => {
                  aoLimpar();
                }}
              >
                <FiRefreshCw />
              </Botao>

              <Botao
                title="Excluir cadastro"
                onClick={() => {
                  handleExcluirPerfil();
                }}
              >
                <FiTrash2 />
              </Botao>
            </ContainerButton>
          </span>

          <PermissoesContainer>
            {permissoes.map(permissao => {
              const permitido = verificaPermissao(permissao.acesso);

              return (
                <CardPermissoes key={permissao.acesso}>
                  <PermissaoText>{permissao.acesso}</PermissaoText>

                  <ToggleContainer
                    permitido={permitido}
                    onClick={() => {
                      togglePermissao(permissao.acesso);
                    }}
                  >
                    <ToggleCircle permitido={permitido} />
                    <ToggleText permitido={permitido}>
                      {permitido ? 'Sim' : 'Não'}
                    </ToggleText>
                  </ToggleContainer>
                </CardPermissoes>
              );
            })}
          </PermissoesContainer>
        </GroupBox>
      )}

      {tabAtiva === 'Pesquisa' && (
        <GroupBox>
          <GroupBoxLegenda>Pesquisa</GroupBoxLegenda>

          <span>
            <InputPerfil
              variant="outlined"
              margin="normal"
              id="perfilPesq"
              label="Perfil"
              name="perfilPesq"
              value={perfilPesquisa}
              onChange={aoAlterarPerfilPesquisa}
            />
          </span>

          <ContainerPesquisa>
            {perfisPesquisados?.map(perfil => (
              <CardPerfisPesquisa
                key={perfil.id}
                onClick={() => {
                  handleSelecionarPerfil(perfil);
                }}
              >
                <div>{perfil.descricao}</div>
              </CardPerfisPesquisa>
            ))}
          </ContainerPesquisa>
        </GroupBox>
      )}
    </Container>
  );
};

export default Perfis;
