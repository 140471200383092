import React, { useState, useCallback, useEffect } from 'react';

import {
  Container,
  InputData,
  ContainerButton,
  AddPacienteButton,
  Repeticao,
  RepeticaoContainer,
  CounterContainer,
  FeitosContainer,
  CardFeitos,
  ConflitosContainer,
  CardAgenda,
  CardAgendaConteudo,
  ContainerBotao,
  Button,
  CirculoCarregandoDiv,
  CirculoCarregando,
} from './styles';
import { FiPlus, FiTrash2 } from 'react-icons/fi';

import api from '../../services/api';
import { returnAxiosError } from '../../utils/returnAxiosError';
import { useAuth } from '../../context/AuthContext';
import { useIndexedDB } from '../../context/IndexedDBContext';
import { useToast } from '../../context/ToastContext';

import ComboboxComFiltro from '../../components/autocomplete';
import Paciente from '../Paciente';
import Agendamento from '../Agendamento';
import Dialog from '../../components/dialog';
import ConfirmationModal from '../../components/confirmationModal';

import { resumeNome } from '../../utils/resumeNome';

import IProfissional from '../../routes/vms/IProfissional';
import IPaciente from '../../routes/vms/IPaciente';
import IAgendamento from '../../routes/vms/IAgendamento';
import ICategoria from '../../routes/vms/ICategoria';
import IListaEspera from '../../routes/vms/IListaEspera';
import Bloqueio from '../Bloqueio';

interface IHorarios {
  id: string;
  nome: string;
}

interface AgendamentosFeitos {
  id: string;
  categoria: ICategoria | undefined;
  profissional: IProfissional | undefined;
  paciente: IPaciente | undefined;
  horario: string;
  recorrencia: string;
  dataPartida: string;
}

interface RetornoConflitos {
  agendamento: IAgendamento;
  descricao: string;
}

const tipoRecorrencia = [
  { id: '1', nome: 'DIARIA' },
  { id: '7', nome: 'SEMANAL' },
  { id: '30', nome: 'MENSAL' },
  { id: '365', nome: 'ANUAL' },
];

interface RecorrenciaProps {
  esperaSelecionada?: IListaEspera;
  onClose?(): void;
  externo: boolean;
}

const Recorrencia: React.FC<RecorrenciaProps> = ({
  esperaSelecionada = undefined,
  onClose,
  externo,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { lerIndexedDB, excluirIndexedDB } = useIndexedDB();

  const [profissional, setProfissional] = useState<string | null>(null);
  const [profissionalErro, setProfissionalErro] = useState('');
  const [data, setData] = useState('');
  const [dataErro, setDataErro] = useState('');
  const [paciente, setPaciente] = useState<string | null>(null);
  const [pacienteErro, setPacienteErro] = useState('');
  const [horaInicio, setHoraInicio] = useState<string | null>(null);
  const [horaInicioErro, setHoraInicioErro] = useState('');
  const [horaFim, setHoraFim] = useState<string | null>(null);
  const [horaFimErro, setHoraFimErro] = useState('');
  const [tipo, setTipo] = useState<string | null>(null);
  const [tipoErro, setTipoErro] = useState('');
  const [categoria, setCategoria] = useState<string | null>(null);
  const [categoriaErro, setCategoriaErro] = useState('');

  const [isPacienteOpen, setPacienteOpen] = useState(false);
  const [isAgendamentoOpen, setAgendamentoOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [espera, setEspera] = useState<IListaEspera | undefined>(undefined);

  const [termino, setTermino] = useState('Nunca');
  const [ocorrencias, setOcorrencias] = useState('1');
  const [agendamentosConflitando, setAgendamentosConflitando] = useState<
    RetornoConflitos[]
  >([]);

  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState<
    IAgendamento | undefined
  >(undefined);
  const [profissionalAgendamento, setProfissionalAgendamento] =
    useState<string>('');
  const [horaAgendamento, setHoraAgendamento] = useState<
    string | null | undefined
  >('');
  const [dataAgendamento, setDataAgendamento] = useState<Date>(new Date());

  const [profissionais, setProfissionais] = useState<IProfissional[]>([]);
  const [pacientes, setPacientes] = useState<IPaciente[]>([]);
  const [horarios, setHorarios] = useState<IHorarios[]>([]);
  const [categorias, setCategorias] = useState<ICategoria[]>([]);

  const [recModalOpen, setRecModalOpen] = useState<boolean>(false);
  const [idExcluir, setIdExcluir] = useState<string>('');

  const [agendamentosFeitos, setAgendamentosFeitos] = useState<
    AgendamentosFeitos[]
  >([]);
  const [bloqueiaPaciente, setBloqueiaPaciente] = useState<boolean>(false);
  const [fazendoAgendamentos, setFazendoAgendamentos] =
    useState<boolean>(false);
  const [retornandoConflitos, setRetornandoConflitos] =
    useState<boolean>(false);

  const atualizaDados = useCallback(async () => {
    try {
      const [
        pacientesResponse,
        profissionaisResponse,
        horariosResponse,
        categoriasResponse,
      ] = await Promise.all([
        api.get<IPaciente[]>('/pacientes/'),
        api.get<IProfissional[]>(
          `/users/profissionais?empresa_id=${user.empresa_id}`,
        ),
        api.get<string[]>(`/empresas/horarios/${user.empresa.url_frontend}`),
        api.get<ICategoria[]>('/categoria/lista', {
          params: { inutilizar: false },
        }),
      ]);

      const horariosCombo: IHorarios[] = horariosResponse.data.map(horario => ({
        id: horario,
        nome: horario,
      }));

      setPacientes(pacientesResponse.data);
      setProfissionais(profissionaisResponse.data);
      setHorarios(horariosCombo);
      setCategorias(categoriasResponse.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }, [user]);

  const aoAlterarProfissional = useCallback(
    (novoProfissional: string | null) => {
      setProfissional(novoProfissional);
      setProfissionalErro('');
    },
    [],
  );

  const aoAlterarData = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setData(event.target.value);
      setDataErro('');
    },
    [],
  );

  const aoAlterarPaciente = useCallback((novoPaciente: string | null) => {
    setPaciente(novoPaciente);
    setPacienteErro('');
  }, []);

  const aoAlterarCategoria = useCallback((categoria: string | null) => {
    setCategoria(categoria);
    setCategoriaErro('');
  }, []);

  const aoAlterarHoraInicio = useCallback((novoHorario: string | null) => {
    setHoraInicio(novoHorario);
    setHoraInicioErro('');
  }, []);

  const aoAlterarHoraFim = useCallback((novoHorario: string | null) => {
    setHoraFim(novoHorario);
    setHoraFimErro('');
  }, []);

  const aoAlterarTipo = useCallback((tipo: string | null) => {
    setTipo(tipo);
    setTipoErro('');
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTermino(event.target.value);

      if (event.target.value === 'Nunca') {
        setOcorrencias('1');
      }
    },
    [],
  );

  const defineConflitos = useCallback(async requestData => {
    setRetornandoConflitos(true);

    const conflitos = await api.get<RetornoConflitos[]>(
      '/agendamentos/conflitos',
      { params: requestData },
    );

    if (conflitos.data) {
      setAgendamentosConflitando(conflitos.data);
    }

    setRetornandoConflitos(false);
  }, []);

  const handleExcluirAgendamento = useCallback(
    async (motivo: string) => {
      if (!agendamentoSelecionado) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir',
          description: 'Nenhum agendamento selecionado para exclusão',
        });

        return;
      }

      if (motivo === '') {
        addToast({
          type: 'error',
          title: 'Erro ao excluir',
          description: 'Motivo não informado',
        });

        return;
      }

      try {
        if (agendamentoSelecionado.inutilizado) {
          await api.delete('/agendamentos/inutilizado', {
            params: { agendamento_id: agendamentoSelecionado.id },
          });
        } else {
          await api.post(
            `/agendamentos/excluir/${agendamentoSelecionado.id}/${true}`,
            {
              motivo: motivo.toUpperCase(),
            },
          );
        }
      } catch (error) {
        addToast(returnAxiosError(error));

        return;
      }

      setModalOpen(false);

      defineConflitos([]);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Agendamento excluido',
      });
    },
    [defineConflitos, addToast, agendamentoSelecionado],
  );

  const handleExcluirRecorrencia = useCallback(
    async (motivo: string) => {
      try {
        setFazendoAgendamentos(true);

        await api.post(`/agendamentos/excluir/${idExcluir}/${true}`, {
          motivo,
        });

        setFazendoAgendamentos(false);

        setAgendamentosFeitos(prevItems =>
          prevItems.filter(item => item.id !== idExcluir),
        );

        setIdExcluir('');
        setRecModalOpen(false);
      } catch (error) {
        addToast(returnAxiosError(error));

        return;
      }
    },
    [addToast, idExcluir],
  );

  const validacoes = useCallback(() => {
    setDataErro('');
    setPacienteErro('');
    setTipoErro('');
    setProfissionalErro('');
    setCategoriaErro('');
    setHoraInicioErro('');
    setHoraFimErro('');

    let erroValidacao = false;
    if (data === '') {
      setDataErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (!paciente) {
      setPacienteErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (!tipo) {
      setTipoErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (!profissional) {
      setProfissionalErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (!categoria) {
      setCategoriaErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (!horaInicio) {
      setHoraInicioErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (!horaFim) {
      setHoraFimErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação',
        description:
          'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return false;
    }
  }, [
    addToast,
    categoria,
    data,
    horaFim,
    horaInicio,
    paciente,
    profissional,
    tipo,
  ]);

  const finalizar = useCallback(() => {
    setData('');
    setPaciente(null);
    setTipo(null);
    setTermino('Nunca');
    setProfissional(null);
    setCategoria(null);
    setHoraInicio(null);
    setHoraFim(null);
    setBloqueiaPaciente(false);
    setOcorrencias('');
    setAgendamentosFeitos([]);
    setAgendamentosConflitando([]);

    if (externo) {
      if (onClose) {
        onClose();
      }
    }
  }, [externo, onClose]);

  const limpaCampos = useCallback(() => {
    setProfissional(null);
    setCategoria(null);
    setHoraInicio(null);
    setHoraFim(null);
  }, []);

  const handleOpenPaciente = () => setPacienteOpen(true);

  // function getRandomInt(min: number, max: number) {
  //   return Math.floor(Math.random() * (max - min + 1)) + min;
  // }

  const handleClosePaciente = useCallback(async () => {
    setPacienteOpen(false);

    lerIndexedDB<string>(
      `${user.empresa.url_frontend}:id-paciente-gravado`,
    ).then(idIndexedDB => {
      if (idIndexedDB !== null) {
        setPaciente(String(idIndexedDB));
        atualizaDados();

        excluirIndexedDB(`${user.empresa.url_frontend}:id-paciente-gravado`);
      }
    });
  }, [atualizaDados, excluirIndexedDB, lerIndexedDB, user]);

  const aoAlterarOcorrencia = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valor = e.target.value;

    if (valor === '' || (!isNaN(Number(valor)) && Number(valor) >= 1)) {
      setOcorrencias(valor);
    }
  };

  const prosseguir = useCallback(async () => {
    if (validacoes() === false) {
      return;
    }

    const requestData = {
      categoria_id: categoria,
      data_ini: data,
      hora_ini: horaInicio,
      hora_fim: horaFim,
      infinita: termino === 'Nunca' ? 'true' : 'false',
      numero_ocorrencias: ocorrencias,
      paciente_id: paciente,
      profissional_id: profissional,
      recorrencia: tipo,
    };

    try {
      setAgendamentosConflitando([]);

      setFazendoAgendamentos(true);

      const response = await api.post<string>(
        '/agendamentos/recorrencia',
        requestData,
      );

      let tipoRecorrencia = '';
      switch (tipo) {
        case '1':
          tipoRecorrencia = 'DIÁRIA';
          break;
        case '7':
          tipoRecorrencia = 'SEMANAL';
          break;
        case '30':
          tipoRecorrencia = 'MENSAL';
          break;
        case '365':
          tipoRecorrencia = 'ANUAL';
          break;
        default:
          break;
      }

      const dataFormatada = new Date(data);
      dataFormatada.setHours(dataFormatada.getHours() + 3);

      const agendamentoFeito: AgendamentosFeitos = {
        id: response.data,
        categoria: categorias.find(c => {
          if (c.id === categoria) {
            return c.nome;
          }

          return '';
        }),
        dataPartida: Intl.DateTimeFormat('pt-BR').format(dataFormatada),
        horario: `${horaInicio}h até ${horaFim}h`,
        profissional: profissionais.find(p => {
          if (p.id === profissional) {
            return p.name;
          }

          return '';
        }),
        paciente: pacientes.find(p => {
          if (p.id === paciente) {
            return p.nome;
          }

          return '';
        }),
        recorrencia: tipoRecorrencia,
      };

      setAgendamentosFeitos([...agendamentosFeitos, agendamentoFeito]);

      setFazendoAgendamentos(false);
      setBloqueiaPaciente(true);

      addToast({
        type: 'success',
        title: 'Agendamentos Cadastrados',
        description: 'Agendamentos recorrentes cadastrados com sucesso',
      });

      if (espera) {
        await api.post(`/espera/excluir/${espera.id}`, {
          motivo: `PACIENTE AGENDADO EM RECORRÊNCIA`,
        });

        setEspera(undefined);
      }
      limpaCampos();
    } catch (error) {
      setFazendoAgendamentos(false);

      const axiosError = returnAxiosError(error);
      if (
        axiosError.title ===
        'Resolva os conflitos de horários para prosseguir com o agendamento'
      ) {
        defineConflitos(requestData);
      }
      addToast(axiosError);
    }
  }, [
    validacoes,
    espera,
    categorias,
    profissionais,
    pacientes,
    categoria,
    data,
    horaFim,
    horaInicio,
    ocorrencias,
    paciente,
    profissional,
    termino,
    tipo,
    agendamentosFeitos,
    addToast,
    limpaCampos,
    defineConflitos,
  ]);

  const handleOpenAgendamento = () => setAgendamentoOpen(true);

  const handleCloseAgendamento = useCallback(() => {
    defineConflitos({
      categoria_id: categoria,
      data_ini: data,
      hora_ini: horaInicio,
      hora_fim: horaFim,
      infinita: termino === 'Nunca',
      numero_ocorrencias: ocorrencias,
      paciente_id: paciente,
      profissional_id: profissional,
      recorrencia: tipo,
    });

    setAgendamentoOpen(false);

    setHoraAgendamento('');
    setProfissionalAgendamento('');
    setDataAgendamento(new Date());
    setAgendamentoSelecionado(undefined);
  }, [
    categoria,
    data,
    horaInicio,
    horaFim,
    termino,
    ocorrencias,
    paciente,
    profissional,
    tipo,
    defineConflitos,
  ]);

  useEffect(() => {
    atualizaDados();
  }, [atualizaDados]);

  useEffect(() => {
    if (esperaSelecionada) {
      setEspera(esperaSelecionada);
      setPaciente(esperaSelecionada.paciente.id);
      setCategoria(esperaSelecionada.categoria.id);
    }
  }, [esperaSelecionada]);

  return fazendoAgendamentos ? (
    <CirculoCarregandoDiv>
      <CirculoCarregando />
    </CirculoCarregandoDiv>
  ) : (
    <Container>
      <ContainerBotao>
        <h1>Recorrência de Agendamentos</h1>
        <div>
          <Button onClick={prosseguir}>Prosseguir</Button>
          <Button onClick={finalizar}>Finalizar</Button>
        </div>
      </ContainerBotao>

      <span
        style={{
          background: '#F5F5F5',
          padding: '1px 10px',
          borderRadius: '8px',
        }}
      >
        <InputData
          variant="outlined"
          margin="normal"
          id="data"
          label="A partir de"
          name="data"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={data}
          onChange={aoAlterarData}
          error={dataErro !== ''}
          helperText={dataErro}
          style={{ marginRight: '8px', flex: 2 }}
        />

        <ContainerButton>
          <ComboboxComFiltro
            opcoes={pacientes}
            value={paciente}
            label="Paciente"
            onChange={aoAlterarPaciente}
            error={pacienteErro !== ''}
            helperText={pacienteErro}
            disabled={bloqueiaPaciente || !!espera}
          />

          <AddPacienteButton
            title="Cadastrar Paciente"
            onClick={handleOpenPaciente}
            disabled={bloqueiaPaciente || !!espera}
          >
            <FiPlus />
          </AddPacienteButton>

          <Dialog isOpen={isPacienteOpen} onClose={handleClosePaciente}>
            <Paciente externa={true} onClose={handleClosePaciente} />
          </Dialog>
        </ContainerButton>

        <ComboboxComFiltro
          opcoes={tipoRecorrencia}
          value={tipo}
          label="Tipo"
          onChange={aoAlterarTipo}
          error={tipoErro !== ''}
          helperText={tipoErro}
          size={2}
          temMargin={true}
        />

        <Repeticao>
          <p>Término: </p>
          <RepeticaoContainer>
            <label>
              <input
                type="radio"
                value="Nunca"
                checked={termino === 'Nunca'}
                onChange={handleChange}
              />
              Nunca
            </label>
            <label>
              <input
                type="radio"
                value="Após"
                checked={termino === 'Após'}
                onChange={handleChange}
              />
              Após
            </label>
          </RepeticaoContainer>
        </Repeticao>

        <CounterContainer oculto={termino !== 'Após'}>
          <input
            type="number"
            value={ocorrencias}
            onChange={aoAlterarOcorrencia}
          />
        </CounterContainer>
      </span>

      <span style={{ padding: '1px 10px' }}>
        <ComboboxComFiltro
          opcoes={profissionais}
          value={profissional}
          label="Profissional"
          onChange={aoAlterarProfissional}
          error={profissionalErro !== ''}
          helperText={profissionalErro}
          size={3}
          temMargin={true}
        />

        <ComboboxComFiltro
          opcoes={categorias}
          value={categoria}
          label="Categoria"
          onChange={aoAlterarCategoria}
          error={categoriaErro !== ''}
          helperText={categoriaErro}
          size={2}
          temMargin={true}
        />

        <ComboboxComFiltro
          opcoes={horarios}
          value={horaInicio}
          label="Hora Início"
          onChange={aoAlterarHoraInicio}
          error={horaInicioErro !== ''}
          helperText={horaInicioErro}
          size={1}
          temMargin={true}
        />

        <ComboboxComFiltro
          opcoes={horarios}
          value={horaFim}
          label="Hora Fim"
          onChange={aoAlterarHoraFim}
          error={horaFimErro !== ''}
          helperText={horaFimErro}
          size={1}
        />
      </span>

      {agendamentosFeitos.length > 0 && (
        <FeitosContainer>
          {agendamentosFeitos.map(feitos => (
            <CardFeitos
              key={feitos.id}
              title={`${feitos.paciente?.nome} - ${feitos.categoria?.nome}`}
              cor={feitos.categoria?.cor}
            >
              <span>
                <p>{resumeNome(feitos.profissional?.name)}</p>
                <FiTrash2
                  onClick={() => {
                    setIdExcluir(feitos.id);
                    setRecModalOpen(true);
                  }}
                />
              </span>
              <div>
                <h3>{feitos.recorrencia}</h3>
                <div>{feitos.dataPartida}</div>
                <div>{feitos.horario}</div>
              </div>
            </CardFeitos>
          ))}
        </FeitosContainer>
      )}

      <ConflitosContainer>
        {retornandoConflitos ? (
          <CirculoCarregandoDiv>
            <CirculoCarregando />
          </CirculoCarregandoDiv>
        ) : (
          agendamentosConflitando.map(conflito => (
            <CardAgenda key={conflito.agendamento.id}>
              <h3>{`${conflito.agendamento.data_criacao
                .split('-')
                .reverse()
                .join('/')}
            ${conflito.agendamento.hora?.slice(0, -3)}`}</h3>

              <CardAgendaConteudo
                title={conflito.agendamento.observacoes}
                cor={conflito.agendamento.categoria?.cor}
                onClick={() => {
                  setAgendamentoSelecionado(conflito.agendamento);
                  setHoraAgendamento(conflito.agendamento.hora?.slice(0, 5));
                  setProfissionalAgendamento(
                    conflito.agendamento.profissional_id,
                  );
                  const dataAjustada = new Date(
                    conflito.agendamento.data_criacao,
                  );
                  dataAjustada.setHours(dataAjustada.getHours() + 3);
                  setDataAgendamento(dataAjustada);
                  handleOpenAgendamento();
                }}
              >
                {conflito.agendamento.inutilizado ? (
                  <div>
                    <p>BLOQUEADO</p>
                    <p>{conflito.agendamento.categoria?.nome}</p>
                  </div>
                ) : (
                  <>
                    <p>
                      {conflito.agendamento.paciente?.nome +
                        ' | ' +
                        conflito.agendamento.categoria?.nome +
                        ': ' +
                        conflito.agendamento.user?.name}
                    </p>
                    <p>{conflito.descricao}</p>
                  </>
                )}
              </CardAgendaConteudo>

              <FiTrash2
                onClick={() => {
                  setAgendamentoSelecionado(conflito.agendamento);
                  setModalOpen(true);
                }}
              />
            </CardAgenda>
          ))
        )}
      </ConflitosContainer>

      <Dialog isOpen={isAgendamentoOpen} onClose={handleCloseAgendamento}>
        {agendamentoSelecionado?.inutilizado ? (
          <Bloqueio
            onClose={handleCloseAgendamento}
            bloqueioParametro={agendamentoSelecionado}
            horaParametro={horaAgendamento ? horaAgendamento : ''}
            profissionalParametro={profissionalAgendamento}
            dataParametro={dataAgendamento}
          />
        ) : (
          <Agendamento
            onClose={handleCloseAgendamento}
            agendamentoParametro={agendamentoSelecionado}
            horaParametro={horaAgendamento ? horaAgendamento : ''}
            profissionalParametro={profissionalAgendamento}
            dataParametro={dataAgendamento}
          />
        )}
      </Dialog>

      <ConfirmationModal
        open={isModalOpen}
        title="Confirmar Exclusão"
        message="Informe o motivo para excluir esse agendamento"
        exigeMotivo={true}
        onConfirm={motivo => {
          handleExcluirAgendamento(motivo);
        }}
        onCancel={() => setModalOpen(false)}
      />

      <ConfirmationModal
        open={recModalOpen}
        title="Confirmar Exclusão"
        message="Informe o motivo para excluir TODOS os horários dessa recorrência"
        exigeMotivo={true}
        onConfirm={motivo => {
          handleExcluirRecorrencia(motivo);
        }}
        onCancel={() => setRecModalOpen(false)}
      />
    </Container>
  );
};

export default Recorrencia;
