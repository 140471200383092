import styled, { keyframes, css } from 'styled-components';
import { TextField, CircularProgress } from '@material-ui/core';
import { tint } from 'polished';

interface AgendamentoProps {
  cor: string | undefined;
}

const getScrollbarStyles = (browser: string) => {
  if (browser !== 'firefox') {
    return css`
      /* Estilizando o Scrollbar para Chrome/Safari */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px; /* Para barras de rolagem horizontais */
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color: ${props =>
          props.theme.secundaria}; /* Cor do "thumb" */
        border-radius: 10px;
        border: 3px solid transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Cor do "track" */
      }

      /* Remove as setas do scrollbar em WebKit */
      &::-webkit-scrollbar-button {
        display: none; /* Remove as setas do scrollbar */
      }
    `;
  } else {
    return css`
      /* Estilizando o Scrollbar para Firefox */
      scrollbar-width: thin; /* Largura do scrollbar: "auto" ou "thin" */
      scrollbar-color: ${props => props.theme.secundaria} #f0f0f0; /* Cor do thumb e do track */
    `;
  }
};

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'firefox';
  } else if (userAgent.includes('Safari')) {
    return 'safari';
  } else {
    return 'other'; // Para outros navegadores
  }
};

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px;
  animation: ${fadeIn} 1s;

  height: 100vh;
  overflow: hidden;

  span {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  h1 {
    color: ${props => props.theme.secundaria};
  }

  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.theme.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.theme.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.theme.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.theme.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.theme.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.theme.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.theme.textoClaro};
    }
  }
`;

export const ContainerBotao = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  div {
    button:first-child {
      margin-right: 5px;
    }
  }
`;

export const Button = styled.button`
  width: 90px;

  margin-bottom: 10px;
  padding: 5px;
  background: none;

  border-radius: 8px;
  border: 2px solid ${props => props.theme.secundaria};
  font-weight: bold;
  color: ${props => props.theme.secundaria};

  &:hover {
    background: ${props => props.theme.secundaria};
    color: white;
  }
`;

export const InputData = styled(TextField)`
  flex: 1;
  width: 100%;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 5;
  width: 100%;

  gap: 8px;
  @media (max-width: 768px) {
    gap: 0;
  }
`;

export const AddPacienteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  align-self: flex-start;
  height: 56px;
  width: 56px;

  margin-top: 15px;
  margin-right: 8px;

  border: none;
  border-radius: 8px;
  background-color: ${props => props.theme.secundaria};

  svg {
    height: 30px;
    width: 30px;
    color: #ffffff;
  }

  &:hover {
    background-color: ${props => props.theme.secundariaShade};
  }

  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    margin: 15px 0px 0px 8px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

export const Repeticao = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  margin: 15px 0px 15px 5px;

  p {
    color: ${props => props.theme.secundaria};
    font-weight: bold;
    font-size: 18px;

    margin-bottom: 14px;
  }

  label {
    font-size: 18px;
  }

  label > input {
    margin-right: 2.5px;
    accent-color: ${props => props.theme.secundaria};
    cursor: pointer;
  }
`;

export const RepeticaoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 5px;
`;

export const CounterContainer = styled.div<{ oculto: boolean }>`
  display: ${props => (props.oculto ? 'none' : 'flex')};

  margin-left: 5px;
  margin-top: 35px;

  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background-color: #f0f2f5;
  border-radius: 6px;

  input {
    display: ${props => (props.oculto ? 'none' : 'flex')};
    width: 45px;
    height: 30px;
    text-align: center;
    border: 2px solid ${props => props.theme.secundaria};
    border-radius: 8px;
    background: transparent;
    font-size: 16px;
    color: #333;
  }
`;

export const FeitosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;

  overflow-y: auto;
  flex-shrink: 0;

  padding: 10px;
  height: 120px;

  ${getScrollbarStyles(detectBrowser())}
`;

export const CardFeitos = styled.div<AgendamentoProps>`
  cursor: pointer;

  border: 1px solid ${props => props.cor};
  border-radius: 5px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5px;
    border-radius: 5px 5px 0px 0px;

    background-color: ${props => props.cor};

    color: white;
    font-size: 15px;

    p {
      font-size: 15px;
      font-weight: bold;
    }

    svg {
      margin-left: 5px;

      width: 20px;
      height: 20px;

      &:hover {
        color: lightgray;
      }
    }
  }

  div {
    h3 {
      font-weight: bold;
    }

    padding: 2.5px;
    display: flex;
    flex-direction: column;

    align-items: center;

    color: ${props => props.cor};
  }
`;

export const ConflitosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;

  width: 100%;
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;

  ${getScrollbarStyles(detectBrowser())};
`;

export const CardAgenda = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    font-size: 18px;
    font-weight: bold;
    text-align: right;

    margin-right: 15px;
    margin-left: -15px;
  }

  svg {
    cursor: pointer;
    color: ${props => props.theme.secundaria};

    margin-left: 5px;
    margin-right: 5px;

    height: 25px;
    width: 25px;

    &:hover {
      color: ${props => props.theme.secundariaShade};
    }
  }
`;

export const CardAgendaConteudo = styled.div<AgendamentoProps>`
  cursor: pointer;

  background-color: ${props => tint(0.8, props.cor ? props.cor : '#f8f8f8')};
  border-left: ${props => `5px solid ${props.cor}`};

  height: 65px;
  width: 100%;

  padding: 5px;

  p + p {
    margin-top: 10px;
    font-weight: normal;
    font-size: 15px;
    color: red;
  }
`;

export const CirculoCarregandoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const CirculoCarregando = styled(CircularProgress)`
  color: #e6e600 !important;
  color: ${props => props.theme.secundaria} !important;

  width: 80px !important;
  height: 80px !important;
  @media (max-width: 610px) {
    width: 40px !important;
    height: 40px !important;
  }
`;
