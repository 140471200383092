import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import INotificacoes from '../routes/vms/INotificacoes';
import api from '../services/api';

interface NotificationsContextData {
  notification_number: number;
  notifications: INotificacoes[];
  fetchNotifications: () => void;
}

const NotificationContext = createContext<NotificationsContextData>(
  {} as NotificationsContextData,
);

const NotificationsProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<INotificacoes[]>([]);

  const fetchNotifications = useCallback(async () => {
    await api
      .get<INotificacoes[]>('/pacientes/aniversariantes')
      .then(response => {
        setNotifications(response.data);
      });
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <NotificationContext.Provider
      value={{
        notification_number: notifications.length,
        notifications,
        fetchNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function useNotifications(): NotificationsContextData {
  const context = useContext(NotificationContext);

  // foi utilizado fora de onde tem acesso
  if (!context) {
    throw new Error(
      'useNotifications deve ser usado dentro de um NotificationsProvider',
    );
  }

  return context;
}

export { NotificationsProvider, useNotifications };
