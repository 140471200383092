import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const Overlay = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Dialog = styled.div`
  position: relative;
  background: white;
  width: 90%;
  max-width: 400px;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const DialogTitle = styled.h2`
  margin: 0 0 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.theme.secundaria};
`;

export const DialogMessage = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.texto};
  font-weight: normal;
  margin: 0 0 20px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const Button = styled.button<{
  variant: 'confirm' | 'cancel' | 'default';
}>`
  flex: 1;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: ${({ variant }) => (variant === 'default' ? 'bold' : 'normal')};
  border-radius: 5px;
  border: ${({ variant, theme }) =>
    variant === 'default' ? `2px solid ${theme.secundaria}` : 'none'};
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${({ variant }) =>
    variant === 'default'
      ? 'white'
      : variant === 'confirm'
      ? '#3CB371'
      : '#dc3545'};
  color: ${({ variant, theme }) =>
    variant === 'default' ? theme.secundaria : 'white'};

  &:hover {
    background-color: ${({ variant, theme }) =>
      variant === 'default'
        ? theme.secundaria
        : variant === 'confirm'
        ? '#2E8B57'
        : '#a71d2a'};
  }
`;

export const InputObs = styled(TextField)`
  flex: 1;
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute; /* Posiciona o botão dentro do .dialog-content */
  top: 8px; /* Espaçamento do topo */
  right: 8px; /* Espaçamento da direita */
  background: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  svg {
    color: ${props => props.theme.secundaria};
    width: 20px;
    height: 20px;
  }

  :hover {
    color: ${props => props.theme.secundariaShade};
  }
`;
