import styled, { keyframes, css } from 'styled-components';
import { TextField } from '@material-ui/core';

interface ToggleProps {
  permitido: boolean | undefined;
}

const fadeIn = keyframes`
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
`;

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'firefox';
  } else if (userAgent.includes('Safari')) {
    return 'safari';
  } else {
    return 'other'; // Para outros navegadores
  }
};

const getScrollbarStyles = (browser: string) => {
  if (browser !== 'firefox') {
    return css`
      /* Estilizando o Scrollbar para Chrome/Safari */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px; /* Para barras de rolagem horizontais */
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color: ${props =>
          props.theme.secundaria}; /* Cor do "thumb" */
        border-radius: 10px;
        border: 3px solid transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Cor do "track" */
        margin-bottom: 5px;
      }

      /* Remove as setas do scrollbar em WebKit */
      &::-webkit-scrollbar-button {
        display: none; /* Remove as setas do scrollbar */
      }
    `;
  } else {
    return css`
      /* Estilizando o Scrollbar para Firefox */
      scrollbar-width: thin; /* Largura do scrollbar: "auto" ou "thin" */
      scrollbar-color: ${props => props.theme.secundaria} #f0f0f0; /* Cor do thumb e do track */
    `;
  }
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  animation: ${fadeIn} 2s;
  overflow: hidden;

  .MuiTextField-root {
    /* contorno do input */
    & .MuiOutlinedInput-root {
      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: ${props => props.theme.secundaria} !important;
        }
      }
    }
    /* Contorno do input no hover */
    &:hover {
      & .MuiOutlinedInput-root {
        fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-color: ${props => props.theme.secundariaShade} !important;
          }
        }
      }
    }
    /* contorno quando foca no input */
    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${props => props.theme.secundariaShade} !important;
      }
    }
    /* cor da fonte do input */
    & .MuiOutlinedInput-input {
      color: ${props => props.theme.texto} !important;
      /* letra maiúscula no input */
      text-transform: uppercase;
    }
    /* label quando preenchido */
    & label.MuiFormLabel-filled {
      color: ${props => props.theme.secundaria} !important;
    }
    /* label em cima do input */
    & label.Mui-focused {
      color: ${props => props.theme.secundariaShade} !important;
    }
    /* seria o placeholder */
    > label {
      color: ${props => props.theme.textoClaro};
    }
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  padding: 5px;
  gap: 5px;
`;

export const TabButton = styled.button<{ ativa: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  padding: 8px;

  border: 2px solid
    ${props => (props.ativa ? 'transparent' : props.theme.secundaria)};
  border-radius: 8px;
  background-color: ${props =>
    props.ativa ? props.theme.secundaria : 'white'};
  color: ${props => (props.ativa ? 'white' : props.theme.secundaria)};
  font-size: 14px;
  cursor: pointer;

  svg {
    height: 30px;
    width: 30px;
  }

  &:hover {
    color: #ffffff;
    background-color: ${props => props.theme.secundaria};
  }
`;

export const GroupBox = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.secundaria} !important;
  border-radius: 10px;
  padding: 0 10px 10px !important;
  height: 100vh;
  overflow: hidden;

  span {
    animation: ${fadeIn} 1s;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const GroupBoxLegenda = styled.legend`
  font-size: 20px !important;
  /* font-weight: bold !important; */
  text-align: left !important;
  color: ${props => props.theme.secundaria};
  width: auto;
  padding: 0px 5px;
  border-bottom: none;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 11px 0px 0px 8px;

  gap: 8px;
  @media (max-width: 768px) {
    margin: 0;
    width: 100%;

    div {
      flex: 1;
    }
  }
`;

export const InputPerfil = styled(TextField)`
  flex: 1;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerPesquisa = styled.div`
  animation: ${fadeIn} 1s;

  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-y: auto;
  height: 100%;
  ${getScrollbarStyles(detectBrowser())};
`;

export const CardPerfisPesquisa = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
  margin-bottom: 5px;

  border: 1px solid ${props => props.theme.secundaria};
  border-radius: 10px;

  cursor: pointer;

  svg {
    color: ${props => props.theme.secundaria};

    height: 20px;
    width: 20px;

    &:hover {
      color: ${props => props.theme.secundariaShade};
    }
  }
`;

export const CardPermissoes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 10px;
  margin: 0 5px 5px 0;

  border: 1px solid ${props => props.theme.secundaria};
  border-radius: 10px;
`;

export const PermissaoText = styled.p`
  font-size: 18px;

  @media (max-width: 610px) {
    width: 150px;
  }
`;

export const Botao = styled.div`
  cursor: pointer;
  animation: ${fadeIn} 1s;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 5px;
  background: ${props => props.theme.secundaria};
  border-radius: 8px;
  border: none;

  color: #fff;

  transition: color 0.3s;
  transition: background-color 0.1s;

  &:hover {
    background-color: ${props => props.theme.secundariaShade};
  }

  &:active {
    background-color: ${props => props.theme.primaria};
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;

export const PermissoesContainer = styled.div`
  height: 100%;
  overflow: auto;
  margin-top: 10px;

  ${getScrollbarStyles(detectBrowser())};
`;

export const ToggleContainer = styled.div<ToggleProps>`
  display: flex;
  margin-right: 20px;
  align-items: center;
  justify-content: space-between;
  width: 75px; /* Ajuste para acomodar o texto */
  height: 36px;
  background: white;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  border: 2px solid
    ${props => (props.permitido ? props.theme.secundaria : 'gray')};
  transition: all 0.4s ease;
`;

export const ToggleCircle = styled.div<ToggleProps>`
  width: 32px;
  height: 32px;
  background: ${props => (props.permitido ? props.theme.secundaria : 'gray')};
  border-radius: 50%;
  position: absolute;
  left: ${props => (!props.permitido ? '0px' : '40px')}; /* Movimento suave */
  transition: all 0.5s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Adiciona sombra ao círculo */
`;

export const ToggleText = styled.span<ToggleProps>`
  color: ${props => (props.permitido ? props.theme.secundaria : 'gray')};
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  left: ${props => (!props.permitido ? '37px' : '10px')}; /* Posição dinâmica */
  transition: all 0.4s ease;
`;
