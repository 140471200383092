import React from 'react';

import { Container, NotificacoesContainer, NotificacoesCard } from './styles';
import INotificacoes from '../../routes/vms/INotificacoes';

interface NotificacoesProps {
  notificacoesPar: INotificacoes[];
}

const Notificacoes: React.FC<NotificacoesProps> = ({ notificacoesPar }) => {
  const formataData = (data: Date): string => {
    const dataNova = new Date(data);

    const dia = dataNova.getDate().toString().padStart(2, '0');
    const mes = (dataNova.getMonth() + 1).toString().padStart(2, '0');

    return `${dia}/${mes}`;
  };

  const verificaData = (data: Date): string => {
    let verificacao = '';

    const dataHoje = new Date();
    const dataAmanha = new Date();
    dataAmanha.setDate(dataAmanha.getDate() + 1);
    const dataAniversario = new Date(data);

    if (
      dataHoje.getDate() === dataAniversario.getDate() &&
      dataHoje.getMonth() === dataAniversario.getMonth()
    ) {
      verificacao = 'HOJE';
    } else if (
      dataAmanha.getDate() === dataAniversario.getDate() &&
      dataAmanha.getMonth() === dataAniversario.getMonth()
    ) {
      verificacao = 'AMANHÃ';
    } else {
      verificacao = 'no dia';
    }

    return verificacao;
  };

  return (
    <Container>
      <h1>Notificações</h1>

      <NotificacoesContainer>
        {notificacoesPar.map(notificacao => {
          return (
            <NotificacoesCard key={notificacao.id}>
              <h4>{notificacao.titulo}</h4>

              <div>
                <p>{notificacao.subtitulo}</p>
                <p>{notificacao.descricao}</p>
                <p
                  className={
                    verificaData(notificacao.data) !== 'no dia' ? 'negrito' : ''
                  }
                >{`${verificaData(notificacao.data)}`}</p>
                <p>{formataData(notificacao.data)}</p>
              </div>
            </NotificacoesCard>
          );
        })}
      </NotificacoesContainer>
    </Container>
  );
};

export default Notificacoes;
