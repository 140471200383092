import React, { useState, useRef, useCallback, useEffect } from 'react';
import ComboboxComFiltro from '../../components/autocomplete';

import {
  Container,
  GroupBoxHistorico,
  GroupBoxPaciente,
  GroupBoxPesquisa,
  GroupBoxContatos,
  GroupBoxLegenda,
  InputCpf,
  InputNome,
  InputNomeMae,
  InputNomePai,
  InputBairro,
  InputCep,
  InputCidade,
  InputComplemento,
  InputEndereco,
  InputNumero,
  InputUf,
  InputData,
  InputTipoContato,
  InputValorContato,
  InputObsContato,
  AddContatoButton,
  ListaContatos,
  CardContato,
  InfoContato,
  TipoContato,
  ExcluirContato,
  BotoesContainer,
  BotaoLimpar,
  BotaoSalvar,
  BotaoExcluir,
  ContainerTabs,
  TabButton,
  ContainerButton,
  InputNomeMaePesquisa,
  InputNomePaiPesquisa,
  InputNomePesquisa,
  PesquisaButton,
  ContainerPesquisa,
  CardPacientePesquisa,
  ContainerHistorico,
  CardHistorico,
  InputCpfResponsavel,
  InputRgResponsavel,
  InputCredencial,
  CardVencida,
  GroupBoxRecorrencia,
  ContainerRecorrencias,
  CardRecorrencias,
  CirculoCarregando,
  CirculoCarregandoDiv,
} from './styles';

import IPaciente from '../../routes/vms/IPaciente';
import IPacienteContatos from '../../routes/vms/IPacienteContatos';
import IConvenio from '../../routes/vms/IConvenio';
import IHistorico from '../../routes/vms/IHistorico';

import apiViaCep from '../../services/apiViaCep';

import api from '../../services/api';
import { useToast } from '../../context/ToastContext';
import { useAuth } from '../../context/AuthContext';
import { useIndexedDB } from '../../context/IndexedDBContext';
import { useNotifications } from '../../context/NotificationContext';
import {
  FiSave,
  FiTrash2,
  FiPlus,
  FiRefreshCw,
  FiFilter,
  FiSearch,
  FiUserPlus,
  FiFileText,
  FiAlertCircle,
  FiRepeat,
} from 'react-icons/fi';
import { returnAxiosError } from '../../utils/returnAxiosError';

import ConfirmationModal from '../../components/confirmationModal';

interface RetornoViaCep {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
}

interface RecorrenciasPaciente {
  id: string;
  horario: string;
  tipoRecorrencia: number;
  profissional: string;
  dia: string;
  categoria: string;
  categoria_cor: string;
  lote: number;
}

interface PacienteProps {
  externa?: boolean;
  onClose?: () => void;
}

const estadosCivis = [
  { id: 'SOLTEIRO', nome: 'SOLTEIRO(A)' },
  { id: 'CASADO', nome: 'CASADO(A)' },
  { id: 'DIVORCIADO', nome: 'DIVORCIADO(A)' },
  { id: 'VIÚVO', nome: 'VIÚVO(A)' },
  { id: 'SEPARADO JUDICIALMENTE', nome: 'SEPARADO(A) JUDICIALMENTE' },
  { id: 'UNIÃO ESTÁVEL', nome: 'UNIÃO ESTÁVEL' },
];

const Paciente: React.FC<PacienteProps> = ({ externa = false, onClose }) => {
  const { addToast } = useToast();
  const { fetchNotifications } = useNotifications();
  const { user } = useAuth();
  const { gravarIndexedDB } = useIndexedDB();

  const [isMobile, setIsMobile] = useState(false);
  const [tabAtiva, setTabAtiva] = useState('Cadastro');
  const [convenios, setConvenios] = useState<IConvenio[]>([]);
  const [contatos, setContatos] = useState<IPacienteContatos[]>([]);
  const [pacientesPesquisa, setPacientesPesquisa] = useState<IPaciente[]>();
  const [historico, setHistorico] = useState<IHistorico[]>([]);
  const [pacientesFiltradosPesquisa, setPacientesFiltradosPesquisa] =
    useState<IPaciente[]>();
  const [pacienteSelecionado, setPacienteSelecionado] = useState<IPaciente>();
  const [somenteVencidos, setSomenteVencidos] = useState<boolean>(false);

  //Campos obrigatórios
  const [cpf, setCpf] = useState<string>('');
  const [cpfErro, setCpfErro] = useState<string>('');
  const [dataNascimento, setDataNascimento] = useState<string>('');
  const [dataNascimentoErro, setDataNascimentoErro] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [nomeErro, setNomeErro] = useState<string>('');
  const [cidade, setCidade] = useState<string>('');
  const [cidadeErro, setCidadeErro] = useState<string>('');
  const [tipoContato, setTipoContato] = useState<string>('');
  const [tipoContatoErro, setTipoContatoErro] = useState<string>('');
  const [valorContato, setValorContato] = useState<string>('');
  const [valorContatoErro, setValorContatoErro] = useState<string>('');
  const [convenio, setConvenio] = useState<string | null>(null);
  const [convenioErro, setConvenioErro] = useState<string>('');

  //Campos opcionais
  const [nomePai, setNomePai] = useState<string>('');
  const [nomeMae, setNomeMae] = useState<string>('');
  const [responsavel, setResponsavel] = useState<string | null>(null);
  const [cpfResponsavel, setCpfResponsavel] = useState<string>('');
  const [rgResponsavel, setRgResponsavel] = useState<string>('');
  const [estadoCivilResponsavel, setEstadoCivilResponsavel] = useState<
    string | null
  >(null);
  const [credencial, setCredencial] = useState<string>('');
  const [validadeCredencial, setValidadeCredencial] = useState<string>('');
  const [cep, setCep] = useState<string>('');
  const [endereco, setEndereco] = useState<string>('');
  const [numero, setNumero] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [complemento, setComplemento] = useState<string>('');
  const [uf, setUf] = useState<string>('');
  const [obsContato, setObsContato] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  //Campos pesquisa
  const [nomePesquisa, setNomePesquisa] = useState<string>('');
  const [nomeMaePesquisa, setNomeMaePesquisa] = useState<string>('');
  const [nomePaiPesquisa, setNomePaiPesquisa] = useState<string>('');
  const [convenioPesquisa, setConvenioPesquisa] = useState<string | null>(null);

  const [recorrenciaSelecionada, setRecorrenciaSelecionada] =
    useState<string>('');
  const [recorrenciasPaciente, setRecorrenciasPaciente] = useState<
    RecorrenciasPaciente[]
  >([]);

  const inputCpf = useRef<HTMLInputElement>(null);
  const inputDataNascimento = useRef<HTMLInputElement>(null);
  const inputNome = useRef<HTMLInputElement>(null);
  const inputNomePai = useRef<HTMLInputElement>(null);
  const inputNomeMae = useRef<HTMLInputElement>(null);
  const inputCep = useRef<HTMLInputElement>(null);
  const inputEndereco = useRef<HTMLInputElement>(null);
  const inputNumero = useRef<HTMLInputElement>(null);
  const inputBairro = useRef<HTMLInputElement>(null);
  const inputComplemento = useRef<HTMLInputElement>(null);
  const inputCidade = useRef<HTMLInputElement>(null);
  const inputUf = useRef<HTMLInputElement>(null);
  const inputTipoContato = useRef<HTMLInputElement>(null);
  const inputNomePesquisa = useRef<HTMLInputElement>(null);
  const inputNomeMaePesquisa = useRef<HTMLInputElement>(null);
  const inputNomePaiPesquisa = useRef<HTMLInputElement>(null);

  const [excluindoRecorrencia, setExcluindoRecorrencia] = useState(false);

  const validacoesPaciente = useCallback(() => {
    setCpfErro('');
    setNomeErro('');
    setDataNascimentoErro('');
    setCidadeErro('');
    setConvenioErro('');

    let erroValidacao = false;
    if (cpf === '') {
      setCpfErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (nome === '') {
      setNomeErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (dataNascimento === '') {
      setDataNascimentoErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (!convenio) {
      setConvenioErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (cidade === '') {
      setCidadeErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação',
        description:
          'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return false;
    }
  }, [addToast, cidade, convenio, cpf, dataNascimento, nome]);

  const verificaCredencialVencida = (data: string | null) => {
    if (!data) {
      return false;
    }

    const dataCredencial = new Date(data);
    const dataHoje = new Date();

    if (dataCredencial < dataHoje) {
      return true;
    }

    return false;
  };

  const formatarData = useCallback((dataISO: string): string => {
    const data = new Date(dataISO);

    const dataFormatada = data.toLocaleDateString('pt-BR');
    const horaFormatada = data.toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });

    return `${dataFormatada} ${horaFormatada}`;
  }, []);

  const formataStringData = useCallback((data: string | null) => {
    if (!data) {
      return '';
    }

    const dataISO = new Date(data);
    dataISO.setHours(dataISO.getHours() + 3);

    const dia = String(dataISO.getDate()).padStart(2, '0');
    const mes = String(dataISO.getMonth() + 1).padStart(2, '0'); // Adiciona 1 ao mês, pois começa em 0
    const ano = dataISO.getFullYear();

    return `${ano}-${mes}-${dia}`;
  }, []);

  const handleAdicionarContato = useCallback(() => {
    if (validacoesPaciente() === false) {
      return;
    }

    setTipoContatoErro('');
    setValorContatoErro('');

    let erroValidacao = false;
    if (tipoContato === '') {
      erroValidacao = true;
      setTipoContatoErro('Informação obrigatória');
    }
    if (valorContato === '') {
      erroValidacao = true;
      setValorContatoErro('Informação obrigatória');
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação dos contatos',
        description:
          'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return;
    }

    const contatoAdicionar: IPacienteContatos = {
      tipo: tipoContato.toUpperCase(),
      valor: valorContato,
      obs: obsContato.toUpperCase(),
    };

    setContatos(contatos => [...contatos, contatoAdicionar]);

    setTipoContato('');
    setValorContato('');
    setObsContato('');

    inputTipoContato.current?.focus();
  }, [
    validacoesPaciente,
    tipoContato,
    setTipoContato,
    setTipoContatoErro,
    valorContato,
    setValorContato,
    setValorContatoErro,
    addToast,
    obsContato,
    setObsContato,
    inputTipoContato,
  ]);

  const handleExcluirContato = useCallback((valor: string) => {
    setContatos(contatos =>
      contatos.filter(contato => contato.valor !== valor),
    );
  }, []);

  const handleLimparCampos = useCallback(() => {
    setCpf('');
    setNome('');
    setDataNascimento('');
    setNomePai('');
    setNomeMae('');
    setConvenio('');
    setCep('');
    setEndereco('');
    setNumero('');
    setBairro('');
    setComplemento('');
    setCidade('');
    setUf('');
    setResponsavel('');
    setCpfResponsavel('');
    setRgResponsavel('');
    setCredencial('');
    setValidadeCredencial('');
    setEstadoCivilResponsavel('');

    setCpfErro('');
    setNomeErro('');
    setDataNascimentoErro('');
    setConvenioErro('');
    setCidadeErro('');

    setTipoContato('');
    setValorContato('');
    setObsContato('');

    setContatos([]);
  }, []);

  const atualizaDados = async () => {
    try {
      const [conveniosResponse, pacientesResponse] = await Promise.all([
        api.get<IConvenio[]>('/convenio/lista'),
        api.get<IPaciente[]>('/pacientes/'),
      ]);

      setConvenios(conveniosResponse.data);
      setPacientesPesquisa(pacientesResponse.data);
      setPacientesFiltradosPesquisa(pacientesResponse.data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  const atualizaRecorrencias = useCallback(
    async (pacienteId: string) => {
      try {
        const recorrencias = await api.get<RecorrenciasPaciente[]>(
          '/agendamentos/lotespaciente',
          {
            params: { paciente_id: pacienteId },
          },
        );

        setRecorrenciasPaciente(recorrencias.data);
      } catch (error) {
        addToast(returnAxiosError(error));
      }
    },
    [addToast],
  );

  const atualizaHistorico = useCallback(
    async (pacienteId: string) => {
      try {
        const historico = await api.get<IHistorico[]>(
          '/pacientes/historico/lista',
          {
            params: { paciente_id: pacienteId },
          },
        );

        setHistorico(historico.data);
      } catch (error) {
        addToast(returnAxiosError(error));
      }
    },
    [addToast],
  );

  const handleSelecionarPaciente = useCallback(
    (paciente: IPaciente) => {
      if (!paciente) {
        return;
      }

      setCpf(paciente.cpf);
      setNome(paciente.nome);

      setDataNascimento(formataStringData(paciente.data_nascimento));
      setNomePai(paciente.nome_pai);
      setNomeMae(paciente.nome_mae);
      setConvenio(paciente.convenio_id);
      setCep(paciente.cep);
      setEndereco(paciente.endereco);
      setNumero(paciente.numero);
      setBairro(paciente.bairro);
      setComplemento(paciente.complemento);
      setCidade(paciente.cidade);
      setUf(paciente.estado);
      setResponsavel(paciente.responsavel ?? '');
      setCpfResponsavel(paciente.cpf_responsavel ?? '');
      setRgResponsavel(paciente.rg_responsavel ?? '');
      setEstadoCivilResponsavel(paciente.estado_civil_responsavel ?? '');
      setCredencial(paciente.credencial ?? '');
      setValidadeCredencial(formataStringData(paciente.validade_credencial));

      setContatos(paciente.contatos);

      setTabAtiva('Cadastro');
      setPacienteSelecionado(paciente);

      atualizaHistorico(paciente.id);
      atualizaRecorrencias(paciente.id);
    },
    [atualizaHistorico, atualizaRecorrencias, formataStringData],
  );

  const handlePesquisa = useCallback(() => {
    let listaFiltrar = pacientesPesquisa;

    if (nomePesquisa !== '') {
      listaFiltrar = listaFiltrar?.filter(pacientes =>
        pacientes.nome.toUpperCase().includes(nomePesquisa.toUpperCase()),
      );
    }

    if (convenioPesquisa) {
      listaFiltrar = listaFiltrar?.filter(
        pacientes => pacientes.convenio_id === convenioPesquisa,
      );
    }

    if (nomePaiPesquisa !== '') {
      listaFiltrar = listaFiltrar?.filter(pacientes =>
        pacientes.nome_pai
          .toUpperCase()
          .includes(nomePaiPesquisa.toUpperCase()),
      );
    }

    if (nomeMaePesquisa !== '') {
      listaFiltrar = listaFiltrar?.filter(pacientes =>
        pacientes.nome_mae
          .toUpperCase()
          .includes(nomeMaePesquisa.toUpperCase()),
      );
    }

    if (somenteVencidos) {
      listaFiltrar = listaFiltrar?.filter(
        paciente =>
          verificaCredencialVencida(paciente.validade_credencial) === true,
      );
    }

    setPacientesFiltradosPesquisa(listaFiltrar);
  }, [
    somenteVencidos,
    nomePesquisa,
    convenioPesquisa,
    nomePaiPesquisa,
    nomeMaePesquisa,
    pacientesPesquisa,
  ]);

  const retornaTipoRecorrencia = useCallback((dias: number) => {
    let tipoRecorrencia = '';

    switch (dias) {
      case 1:
        tipoRecorrencia = 'DIÁRIA';
        break;
      case 7:
        tipoRecorrencia = 'SEMANAL';
        break;
      case 30:
        tipoRecorrencia = 'MENSAL';
        break;
      case 365:
        tipoRecorrencia = 'ANUAL';
        break;
    }

    return tipoRecorrencia;
  }, []);

  const handleLimparPesquisa = useCallback(() => {
    setNomePesquisa('');
    setConvenioPesquisa(null);
    setNomePaiPesquisa('');
    setNomeMaePesquisa('');

    setPacientesFiltradosPesquisa(pacientesPesquisa);
  }, [pacientesPesquisa]);

  const handleExcluirPaciente = useCallback(async () => {
    if (!pacienteSelecionado) {
      addToast({
        type: 'error',
        title: 'Erro ao excluir',
        description: 'Nenhum paciente selecionado para a exclusão.',
      });
      return;
    }

    await api.delete(`/pacientes/${pacienteSelecionado.id}`);

    addToast({
      type: 'success',
      title: 'Parabéns',
      description: 'Paciente excluído com sucesso!',
    });

    setPacienteSelecionado(undefined);
    handleLimparCampos();
    atualizaDados();
  }, [pacienteSelecionado, addToast, handleLimparCampos]);

  const handleExcluirAgendamento = useCallback(
    async (motivo: string) => {
      if (!recorrenciaSelecionada) {
        addToast({
          type: 'error',
          title: 'Erro ao excluir',
          description: 'Nenhuma recorrência selecionada para exclusão',
        });

        return;
      }

      if (motivo === '') {
        addToast({
          type: 'error',
          title: 'Erro ao excluir',
          description: 'Motivo não informado',
        });

        return;
      }

      try {
        setExcluindoRecorrencia(true);

        await api.post(
          `/agendamentos/excluir/${recorrenciaSelecionada}/${true}`,
          {
            motivo: motivo.toUpperCase(),
          },
        );

        setExcluindoRecorrencia(false);
      } catch (error) {
        addToast(returnAxiosError(error));

        return;
      }

      setModalOpen(false);
      setRecorrenciaSelecionada('');

      if (pacienteSelecionado) {
        atualizaRecorrencias(pacienteSelecionado.id);
      }

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Agendamento excluido',
      });
    },
    [
      addToast,
      recorrenciaSelecionada,
      atualizaRecorrencias,
      pacienteSelecionado,
    ],
  );

  const aoAlterarNomePesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomePesquisa(event.target.value);
    },
    [],
  );

  const aoAlterarNomeMaePesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomeMaePesquisa(event.target.value);
    },
    [],
  );

  const aoAlterarNomePaiPesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomePaiPesquisa(event.target.value);
    },
    [],
  );

  const aoAlterarConvenioPesquisa = useCallback((id: string | null) => {
    setConvenioPesquisa(id);
  }, []);

  const aoAlterarCnpjcpf = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCpf(event.target.value);
      setCpfErro('');
    },
    [],
  );

  const aoAlterarDataInicial = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDataNascimento(event.target.value);
      setDataNascimentoErro('');
    },
    [],
  );

  const aoAlterarNome = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNome(event.target.value);
      setNomeErro('');
    },
    [],
  );

  const aoAlterarNomePai = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomePai(event.target.value);
    },
    [],
  );

  const aoAlterarNomeMae = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomeMae(event.target.value);
    },
    [],
  );

  const aoAlterarResponsavel = (responsavel: string | null) => {
    setResponsavel(responsavel);
  };

  const aoAlterarCpfResponsavel = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCpfResponsavel(event.target.value);
    },
    [],
  );

  const aoAlterarRgResponsavel = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRgResponsavel(event.target.value);
    },
    [],
  );

  const aoAlterarCredencial = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCredencial(event.target.value);
    },
    [],
  );

  const aoAlterarEstadoCivilResponsavel = (responsavel: string | null) => {
    setEstadoCivilResponsavel(responsavel);
  };

  const aoAlterarValidadeCredencial = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValidadeCredencial(event.target.value);
    },
    [],
  );

  const aoAlterarConvenio = (novoConvenio: string | null) => {
    setConvenio(novoConvenio);
    setConvenioErro('');
  };

  const aoAlterarCep = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCep(event.target.value);
    },
    [],
  );

  const aoSairCep = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      let cepTemp: string = event.target.value;

      cepTemp = cepTemp.replace(/[^0-9]/g, '');

      if (endereco === '') {
        apiViaCep.get<RetornoViaCep>(`/${cepTemp}/json/`).then(response => {
          if (response.data) {
            const retornoCep: RetornoViaCep = response.data;
            if (retornoCep) {
              if (retornoCep.logradouro) {
                setEndereco(retornoCep.logradouro);
              }
              if (retornoCep.localidade) {
                setCidade(retornoCep.localidade);
              }
              if (retornoCep.bairro) {
                setBairro(retornoCep.bairro);
              }
              if (retornoCep.complemento) {
                setComplemento(retornoCep.complemento);
              }
              if (retornoCep.uf) {
                setUf(retornoCep.uf);
              }
              inputNumero.current?.focus();
            }
          }
        });
      }

      if (cepTemp.length === 8) {
        const cepFormat = `${cepTemp.substr(0, 5)}-${cepTemp.substr(5, 3)}`;
        setCep(cepFormat);
      }
    },
    [setCep, endereco],
  );

  const aoAlterarEndereco = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEndereco(event.target.value);
    },
    [],
  );

  const aoAlterarNumero = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNumero(event.target.value);
    },
    [],
  );

  const aoAlterarBairro = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBairro(event.target.value);
    },
    [],
  );

  const aoAlterarComplemento = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComplemento(event.target.value);
    },
    [],
  );

  const aoAlterarCidade = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCidade(event.target.value);
      setCidadeErro('');
    },
    [],
  );

  const aoAlterarUf = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUf(event.target.value);
    },
    [],
  );

  const aoAlterarTipo = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTipoContato(event.target.value);
    },
    [],
  );

  const aoAlterarValor = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValorContato(event.target.value);
    },
    [],
  );

  const aoAlterarObs = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setObsContato(event.target.value);
    },
    [],
  );

  const aoSalvar = useCallback(async () => {
    if (validacoesPaciente() === false) {
      return;
    }

    if (contatos.length === 0) {
      addToast({
        type: 'error',
        title: 'Validação Contatos',
        description:
          'Pelo menos 1 contato deve ser informado para realizar o cadastro.',
      });
      return;
    }

    const pacienteGravar: Omit<IPaciente, 'id'> = {
      cpf,
      nome: nome.toUpperCase(),
      data_nascimento: formataStringData(dataNascimento),
      nome_pai: nomePai.toUpperCase(),
      nome_mae: nomeMae.toUpperCase(),
      convenio_id: convenio ? convenio : '',
      cep,
      endereco: endereco.toUpperCase(),
      numero,
      bairro: bairro.toUpperCase(),
      complemento: complemento.toUpperCase(),
      cidade: cidade.toUpperCase(),
      estado: uf.toUpperCase(),
      responsavel: responsavel ?? '',
      cpf_responsavel: cpfResponsavel,
      rg_responsavel: rgResponsavel,
      estado_civil_responsavel: estadoCivilResponsavel ?? '',
      credencial: credencial,
      validade_credencial: validadeCredencial
        ? formataStringData(validadeCredencial)
        : null,
      contatos,
    };

    try {
      if (pacienteSelecionado) {
        await api.put(`pacientes/${pacienteSelecionado.id}`, {
          paciente: pacienteGravar,
        });
      } else {
        const retornoGravacao = await api.post('pacientes', pacienteGravar);

        if (externa) {
          // VOU SALVAR O NOVO CLIENTE NO STORAGE PARA USAR DEPOIS ONDE PRECISAR
          const novoPaciente: IPaciente = retornoGravacao.data;
          gravarIndexedDB(
            `${user.empresa.url_frontend}:id-paciente-gravado`,
            novoPaciente.id,
          );

          setTimeout(() => {
            if (onClose) {
              onClose();
            }
          }, 1000);
        }
      }

      addToast({
        type: 'success',
        title: 'Paciente salvo com sucesso!!!',
      });
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }

    handleLimparCampos();
    atualizaDados();
    fetchNotifications();
  }, [
    pacienteSelecionado,
    fetchNotifications,
    formataStringData,
    validacoesPaciente,
    addToast,
    bairro,
    cep,
    cidade,
    complemento,
    contatos,
    convenio,
    cpf,
    dataNascimento,
    endereco,
    gravarIndexedDB,
    nome,
    nomeMae,
    nomePai,
    numero,
    uf,
    user.empresa.url_frontend,
    handleLimparCampos,
    externa,
    onClose,
    cpfResponsavel,
    credencial,
    estadoCivilResponsavel,
    responsavel,
    rgResponsavel,
    validadeCredencial,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    atualizaDados();
  }, []);

  useEffect(() => {
    handlePesquisa();
  }, [somenteVencidos, handlePesquisa]);

  return excluindoRecorrencia ? (
    <CirculoCarregandoDiv>
      <CirculoCarregando />
    </CirculoCarregandoDiv>
  ) : (
    <Container>
      {externa || (
        <ContainerTabs>
          <TabButton
            onClick={() => {
              setTabAtiva('Cadastro');
            }}
            ativa={tabAtiva === 'Cadastro'}
          >
            <FiUserPlus />
          </TabButton>
          <TabButton
            onClick={() => {
              setTabAtiva('Recorrencia');
            }}
            ativa={tabAtiva === 'Recorrencia'}
          >
            <FiRepeat />
          </TabButton>
          <TabButton
            onClick={() => {
              setTabAtiva('Historico');
            }}
            ativa={tabAtiva === 'Historico'}
          >
            <FiFileText />
          </TabButton>
          <TabButton
            onClick={() => {
              setTabAtiva('Pesquisa');
            }}
            ativa={tabAtiva === 'Pesquisa'}
          >
            <FiFilter />
          </TabButton>
        </ContainerTabs>
      )}

      {tabAtiva === 'Historico' && (
        <>
          <GroupBoxHistorico>
            <GroupBoxLegenda>Histórico</GroupBoxLegenda>

            <ContainerHistorico>
              {historico.map(card => (
                <CardHistorico key={card.id}>
                  <h3>{`${formatarData(card.data_inclusao)} - ${
                    card.descricao
                  }`}</h3>
                  <p>{card.user.name}</p>
                  <p>{card.motivo}</p>
                </CardHistorico>
              ))}
            </ContainerHistorico>
          </GroupBoxHistorico>
        </>
      )}

      {tabAtiva === 'Cadastro' && (
        <>
          <GroupBoxPaciente>
            <GroupBoxLegenda>Paciente</GroupBoxLegenda>

            <span>
              <InputCpf
                variant="outlined"
                margin="normal"
                id="cnpjcpf"
                label="CPF"
                name="cnpjcpf"
                inputRef={inputCpf}
                value={cpf}
                onChange={aoAlterarCnpjcpf}
                error={cpfErro !== ''}
                helperText={cpfErro}
              />

              <InputNome
                variant="outlined"
                margin="normal"
                id="nome"
                label="Nome"
                name="nome"
                inputRef={inputNome}
                value={nome}
                onChange={aoAlterarNome}
                error={nomeErro !== ''}
                helperText={nomeErro}
              />

              <InputData
                variant="outlined"
                margin="normal"
                id="dataNascimento"
                label="Data Nascimento"
                name="dataNascimento"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={inputDataNascimento}
                value={dataNascimento}
                onChange={aoAlterarDataInicial}
                error={dataNascimentoErro !== ''}
                helperText={dataNascimentoErro}
              />
            </span>

            <span>
              <InputNomePai
                variant="outlined"
                margin="normal"
                id="nomePai"
                label="Nome Pai"
                name="nomePai"
                inputRef={inputNomePai}
                value={nomePai}
                onChange={aoAlterarNomePai}
              />

              <InputNomeMae
                variant="outlined"
                margin="normal"
                id="nomeMae"
                label="Nome Mãe"
                name="nomeMae"
                inputRef={inputNomeMae}
                value={nomeMae}
                onChange={aoAlterarNomeMae}
              />
            </span>

            <span>
              <ComboboxComFiltro
                opcoes={[
                  { id: 'MÃE', nome: 'MÃE' },
                  { id: 'PAI', nome: 'PAI' },
                ]}
                value={responsavel}
                label="Responsável"
                onChange={aoAlterarResponsavel}
                temMargin={true}
                size={0.6}
              />

              <InputCpfResponsavel
                variant="outlined"
                margin="normal"
                id="cpfResponsavel"
                label="CPF Responsável"
                name="cpfResponsavel"
                value={cpfResponsavel}
                onChange={aoAlterarCpfResponsavel}
              />

              <InputRgResponsavel
                variant="outlined"
                margin="normal"
                id="rgResponsavel"
                label="RG Responsável"
                name="rgResponsavel"
                value={rgResponsavel}
                onChange={aoAlterarRgResponsavel}
              />

              <ComboboxComFiltro
                opcoes={estadosCivis}
                value={estadoCivilResponsavel}
                label="Estado Civil Responsável"
                onChange={aoAlterarEstadoCivilResponsavel}
              />
            </span>

            <span>
              <InputCep
                variant="outlined"
                margin="normal"
                id="cep"
                label="CEP"
                name="cep"
                inputRef={inputCep}
                value={cep}
                onChange={aoAlterarCep}
                onBlur={aoSairCep}
              />

              <InputEndereco
                variant="outlined"
                margin="normal"
                id="enderecoCliente"
                label="Endereço"
                name="enderecoCliente"
                inputRef={inputEndereco}
                value={endereco}
                onChange={aoAlterarEndereco}
              />

              <InputNumero
                variant="outlined"
                margin="normal"
                id="numeroCliente"
                label="Número"
                name="numeroCliente"
                inputRef={inputNumero}
                value={numero}
                onChange={aoAlterarNumero}
              />
            </span>

            <span>
              <InputBairro
                variant="outlined"
                margin="normal"
                id="bairroCliente"
                label="Bairro"
                name="bairroCliente"
                inputRef={inputBairro}
                value={bairro}
                onChange={aoAlterarBairro}
              />

              <InputComplemento
                variant="outlined"
                margin="normal"
                id="complementoCliente"
                label="Complemento"
                name="complementoCliente"
                inputRef={inputComplemento}
                value={complemento}
                onChange={aoAlterarComplemento}
              />
            </span>

            <span>
              <InputCidade
                variant="outlined"
                margin="normal"
                id="cidadeCliente"
                label="Cidade"
                name="cidadeCliente"
                inputRef={inputCidade}
                value={cidade}
                onChange={aoAlterarCidade}
                error={cidadeErro !== ''}
                helperText={cidadeErro}
              />

              <InputUf
                variant="outlined"
                margin="normal"
                id="ufCliente"
                label="UF"
                name="ufCliente"
                inputRef={inputUf}
                value={uf}
                onChange={aoAlterarUf}
              />
            </span>

            <span>
              <ComboboxComFiltro
                opcoes={convenios}
                value={convenio}
                label="Convênio"
                onChange={aoAlterarConvenio}
                error={convenioErro !== ''}
                helperText={convenioErro}
                temMargin={true}
                size={4}
              />

              <InputCredencial
                variant="outlined"
                margin="normal"
                id="credencial"
                label="Credencial"
                name="credencial"
                value={credencial}
                onChange={aoAlterarCredencial}
              />

              <InputData
                variant="outlined"
                margin="normal"
                id="validadeCredencial"
                label="Validade Credencial"
                name="validadeCredencial"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={validadeCredencial}
                onChange={aoAlterarValidadeCredencial}
              />
            </span>

            <GroupBoxContatos>
              <GroupBoxLegenda>Contatos</GroupBoxLegenda>

              <span>
                <InputTipoContato
                  variant="outlined"
                  margin="normal"
                  id="tipoContato"
                  label="Tipo Contato"
                  name="tipoContato"
                  value={tipoContato}
                  onChange={aoAlterarTipo}
                  error={tipoContatoErro !== ''}
                  inputRef={inputTipoContato}
                  helperText={tipoContatoErro}
                />

                <InputValorContato
                  variant="outlined"
                  margin="normal"
                  id="valorContato"
                  label="Dados Contato"
                  name="valorContato"
                  value={valorContato}
                  onChange={aoAlterarValor}
                  error={valorContatoErro !== ''}
                  helperText={valorContatoErro}
                />

                <ContainerButton>
                  <InputObsContato
                    variant="outlined"
                    margin="normal"
                    id="obsContato"
                    label="Observações"
                    name="obsContato"
                    value={obsContato}
                    onChange={aoAlterarObs}
                  />

                  <AddContatoButton onClick={handleAdicionarContato}>
                    <FiPlus />
                  </AddContatoButton>
                </ContainerButton>
              </span>

              <ListaContatos>
                {contatos.map(contato => {
                  return isMobile ? (
                    <CardContato key={contato.valor}>
                      <FiTrash2
                        onClick={() => {
                          handleExcluirContato(contato.valor);
                        }}
                      />
                      <div>{contato.tipo}</div>
                      <div>{contato.valor}</div>
                      <div>{contato.obs}</div>
                    </CardContato>
                  ) : (
                    <CardContato key={contato.valor}>
                      <TipoContato>{contato.tipo}</TipoContato>

                      <InfoContato
                        variant="outlined"
                        margin="normal"
                        id="valorContato"
                        label="Dados Contato"
                        name="valorContato"
                        value={contato.valor}
                        inputProps={{ readOnly: true }}
                      />

                      <ContainerButton>
                        <InfoContato
                          variant="outlined"
                          margin="normal"
                          id="obsContato"
                          label="Observações"
                          name="obsContato"
                          value={contato.obs ? contato.obs : ''}
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />

                        <ExcluirContato
                          onClick={() => {
                            handleExcluirContato(contato.valor);
                          }}
                        >
                          <FiTrash2 />
                        </ExcluirContato>
                      </ContainerButton>
                    </CardContato>
                  );
                })}
              </ListaContatos>
            </GroupBoxContatos>
          </GroupBoxPaciente>

          <BotoesContainer>
            <BotaoSalvar onClick={aoSalvar}>
              <FiSave />
              Salvar
            </BotaoSalvar>

            <BotaoLimpar onClick={handleLimparCampos}>
              <FiRefreshCw />
              Limpar
            </BotaoLimpar>

            {externa || (
              <BotaoExcluir onClick={handleExcluirPaciente}>
                <FiTrash2 />
                Excluir
              </BotaoExcluir>
            )}
          </BotoesContainer>
        </>
      )}

      {tabAtiva === 'Recorrencia' && (
        <GroupBoxRecorrencia>
          <GroupBoxLegenda>Recorrências</GroupBoxLegenda>

          <ContainerRecorrencias>
            {recorrenciasPaciente.map(recorrencia => (
              <CardRecorrencias
                key={recorrencia.lote}
                cor={recorrencia.categoria_cor}
              >
                <div>
                  <span>
                    {retornaTipoRecorrencia(recorrencia.tipoRecorrencia)}
                  </span>
                  <span>
                    {recorrencia.tipoRecorrencia !== 1 && recorrencia.dia}
                  </span>
                  <p>{recorrencia.horario}</p>
                </div>
                <div>
                  <span>{recorrencia.profissional}</span>
                  <p>{recorrencia.categoria}</p>
                </div>
                <div
                  onClick={() => {
                    setModalOpen(true);
                    setRecorrenciaSelecionada(recorrencia.id);
                  }}
                >
                  <FiTrash2 />
                </div>
              </CardRecorrencias>
            ))}
          </ContainerRecorrencias>
        </GroupBoxRecorrencia>
      )}

      {tabAtiva === 'Pesquisa' && (
        <GroupBoxPesquisa>
          <GroupBoxLegenda>Pesquisa</GroupBoxLegenda>

          <span>
            <InputNomePesquisa
              variant="outlined"
              margin="normal"
              id="nomePesq"
              label="Nome"
              name="nomePesq"
              inputRef={inputNomePesquisa}
              value={nomePesquisa}
              onChange={aoAlterarNomePesquisa}
            />

            <ComboboxComFiltro
              opcoes={convenios}
              value={convenioPesquisa}
              label="Convênio"
              onChange={aoAlterarConvenioPesquisa}
            />
          </span>

          <span>
            <InputNomePaiPesquisa
              variant="outlined"
              margin="normal"
              id="nomePaiPesq"
              label="Nome Pai"
              name="nomePaiPesq"
              inputRef={inputNomePaiPesquisa}
              value={nomePaiPesquisa}
              onChange={aoAlterarNomePaiPesquisa}
            />

            <ContainerButton>
              <InputNomeMaePesquisa
                variant="outlined"
                margin="normal"
                id="nomeMaePesq"
                label="Nome Mãe"
                name="nomeMaePesq"
                inputRef={inputNomeMaePesquisa}
                value={nomeMaePesquisa}
                onChange={aoAlterarNomeMaePesquisa}
              />

              <PesquisaButton onClick={handlePesquisa}>
                <FiSearch />
              </PesquisaButton>

              <PesquisaButton onClick={handleLimparPesquisa}>
                <FiRefreshCw />
              </PesquisaButton>
            </ContainerButton>
          </span>

          <label>
            <input
              type="checkbox"
              checked={somenteVencidos}
              onChange={() => {
                setSomenteVencidos(!somenteVencidos);
              }}
            />
            Somente Credencial Vencida
          </label>

          <ContainerPesquisa>
            {pacientesFiltradosPesquisa?.map(paciente => (
              <CardPacientePesquisa
                key={paciente.id}
                onClick={() => {
                  handleSelecionarPaciente(paciente);
                }}
              >
                <div>
                  <span>
                    <p>{paciente.nome}</p>
                    <p> - </p>
                    <p>{paciente.convenio?.nome}</p>
                  </span>
                  <p>
                    {paciente.responsavel === 'MÃE' || !paciente.responsavel
                      ? paciente.nome_mae
                      : paciente.nome_pai}
                  </p>
                  <p>
                    {
                      paciente.contatos.find(contato =>
                        /FONE|CEL/i.test(contato.tipo),
                      )?.valor
                    }
                  </p>
                </div>
                <CardVencida
                  vencido={verificaCredencialVencida(
                    paciente.validade_credencial,
                  )}
                >
                  <FiAlertCircle />
                </CardVencida>
              </CardPacientePesquisa>
            ))}
          </ContainerPesquisa>
        </GroupBoxPesquisa>
      )}

      <ConfirmationModal
        open={modalOpen}
        title="Confirmar Exclusão"
        message="Informe o motivo para excluir TODOS os horários dessa recorrência"
        exigeMotivo={true}
        onConfirm={motivo => {
          handleExcluirAgendamento(motivo);
        }}
        onCancel={() => setModalOpen(false)}
      />
    </Container>
  );
};

export default Paciente;
