import React, { useState, useCallback, useEffect } from 'react';
import ComboboxComFiltro from '../../components/autocomplete';

import {
  Container,
  GroupBoxUsuario,
  GroupBoxLegenda,
  InputNome,
  InputEmail,
  InputSenha,
  BotoesContainer,
  BotaoLimpar,
  BotaoSalvar,
  InputNomePesquisa,
  InputEmailPesquisa,
  ContainerPesquisa,
  CardPesquisa,
} from './styles';

import IPerfis from '../../routes/vms/IPerfis';
import IUsuario from '../../routes/vms/IUsuario';

import api from '../../services/api';
import { useToast } from '../../context/ToastContext';
import { useAuth } from '../../context/AuthContext';
import { FiSave, FiTrash2, FiRefreshCw } from 'react-icons/fi';
import { returnAxiosError } from '../../utils/returnAxiosError';

interface PerfilCombo {
  id: string;
  nome: string;
}

const Usuario: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [usuariosPesquisados, setUsuariosPesquisados] = useState<IUsuario[]>(
    [],
  );
  const [perfis, setPerfis] = useState<PerfilCombo[]>([]);

  //Campos obrigatórios
  const [email, setEmail] = useState<string>('');
  const [emailErro, setEmailErro] = useState<string>('');
  const [nome, setNome] = useState<string>('');
  const [nomeErro, setNomeErro] = useState<string>('');
  const [senha, setSenha] = useState<string>('');
  const [senhaErro, setSenhaErro] = useState<string>('');
  const [perfil, setPerfil] = useState<string | null>(null);
  const [perfilErro, setPerfilErro] = useState<string>('');

  //Campos pesquisa
  const [nomePesquisa, setNomePesquisa] = useState<string>('');
  const [emailPesquisa, setEmailPesquisa] = useState<string>('');
  const [perfilPesquisa, setPerfilPesquisa] = useState<string | null>(null);

  const aoAlterarEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
      setEmailErro('');
    },
    [],
  );

  const aoAlterarEmailPesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmailPesquisa(event.target.value);
    },
    [],
  );

  const aoAlterarNome = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNome(event.target.value);
      setNomeErro('');
    },
    [],
  );

  const aoAlterarSenha = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSenha(event.target.value);
      setSenhaErro('');
    },
    [],
  );

  const atualizaDados = useCallback(async () => {
    try {
      const [usuariosResponse, perfisResponse] = await Promise.all([
        api.get<IUsuario[]>('/users/por-empresa', {
          params: { empresa_id: user.empresa_id },
        }),
        api.get<IPerfis[]>('/perfis/todos'),
      ]);

      const perfisFormatados: PerfilCombo[] = perfisResponse.data.map(
        perfil => ({
          id: perfil.id,
          nome: perfil.descricao,
        }),
      );

      setUsuarios(usuariosResponse.data);
      setUsuariosPesquisados(usuariosResponse.data);
      setPerfis(perfisFormatados);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }, [user]);

  const handleInativarUsuario = useCallback(
    async (email: string) => {
      await api.post(`/users/ativar`, { email });

      addToast({
        type: 'success',
        title: 'Parabéns',
        description: 'Usuário excluído com sucesso!',
      });

      atualizaDados();
    },
    [addToast, atualizaDados],
  );

  const aoLimpar = useCallback(() => {
    setNome('');
    setNomeErro('');
    setEmail('');
    setEmailErro('');
    setSenha('');
    setSenhaErro('');
    setPerfil(null);
    setPerfilErro('');
  }, []);

  const validacoesUsuario = useCallback(() => {
    setNomeErro('');
    setEmailErro('');
    setSenhaErro('');
    setPerfilErro('');

    let erroValidacao = false;
    if (nome === '') {
      setNomeErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (email === '') {
      setEmailErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (senha === '') {
      setSenhaErro('Informação obrigatória!');
      erroValidacao = true;
    }
    if (!perfil) {
      setPerfilErro('Informação obrigatória!');
      erroValidacao = true;
    }

    if (erroValidacao) {
      addToast({
        type: 'info',
        title: 'Pendências na validação',
        description:
          'Efetue as correções indicadas em cada campo e tente novamente.',
      });
      return false;
    }
  }, [addToast, nome, perfil, email, senha]);

  const aoSalvar = useCallback(async () => {
    if (validacoesUsuario() === false) {
      return;
    }

    const usuarioGravar: Omit<IUsuario, 'id'> = {
      name: nome.toUpperCase(),
      email,
      password: senha,
      empresa_id: user.empresa_id,
      profissional: true,
      perfil_id: perfil ? perfil : '',
    };

    try {
      await api.post('users', usuarioGravar);

      addToast({
        type: 'success',
        title: 'Usuário salvo com sucesso!!!',
      });
    } catch (error) {
      addToast(returnAxiosError(error));

      return;
    }

    aoLimpar();
    atualizaDados();
  }, [
    addToast,
    email,
    nome,
    perfil,
    senha,
    user.empresa_id,
    validacoesUsuario,
    aoLimpar,
    atualizaDados,
  ]);

  const aoAlterarNomePesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomePesquisa(event.target.value);
    },
    [],
  );

  const aoAlterarPerfil = (perfil: string | null) => {
    setPerfil(perfil);
    setPerfilErro('');
  };

  const aoAlterarPerfilPesquisa = (responsavel: string | null) => {
    setPerfilPesquisa(responsavel);
  };

  useEffect(() => {
    let listaFiltrar = usuarios;

    if (nomePesquisa !== '') {
      listaFiltrar = listaFiltrar?.filter(user =>
        user.name.toUpperCase().includes(nomePesquisa.toUpperCase()),
      );
    }

    if (emailPesquisa !== '') {
      listaFiltrar = listaFiltrar?.filter(user =>
        user.email.includes(emailPesquisa),
      );
    }

    if (perfilPesquisa) {
      listaFiltrar = listaFiltrar?.filter(
        user => user.perfil_id === perfilPesquisa,
      );
    }

    setUsuariosPesquisados(listaFiltrar);
  }, [emailPesquisa, nomePesquisa, usuarios, perfilPesquisa, perfis]);

  useEffect(() => {
    atualizaDados();
  }, [atualizaDados]);

  return (
    <Container>
      <GroupBoxUsuario>
        <GroupBoxLegenda>Usuário</GroupBoxLegenda>

        <span>
          <InputNome
            variant="outlined"
            margin="normal"
            id="nome"
            label="Nome"
            name="nome"
            value={nome}
            onChange={aoAlterarNome}
            error={nomeErro !== ''}
            helperText={nomeErro}
          />

          <InputEmail
            variant="outlined"
            margin="normal"
            id="email"
            label="Email"
            name="email"
            value={email}
            onChange={aoAlterarEmail}
            error={emailErro !== ''}
            helperText={emailErro}
          />

          <InputSenha
            variant="outlined"
            margin="normal"
            id="senha"
            label="Senha"
            name="senha"
            type="password"
            value={senha}
            onChange={aoAlterarSenha}
            error={senhaErro !== ''}
            helperText={senhaErro}
          />
        </span>

        <span>
          <ComboboxComFiltro
            opcoes={perfis}
            value={perfil}
            label="Perfil"
            onChange={aoAlterarPerfil}
            temMargin={true}
            error={perfilErro !== ''}
            helperText={perfilErro}
          />
        </span>

        <GroupBoxLegenda>Pesquisa</GroupBoxLegenda>

        <span>
          <InputNomePesquisa
            variant="outlined"
            margin="normal"
            id="nomePesq"
            label="Nome"
            name="nomePesq"
            value={nomePesquisa}
            onChange={aoAlterarNomePesquisa}
          />

          <InputEmailPesquisa
            variant="outlined"
            margin="normal"
            id="emailPesq"
            label="Email"
            name="emailPesq"
            value={emailPesquisa}
            onChange={aoAlterarEmailPesquisa}
          />

          <ComboboxComFiltro
            opcoes={perfis}
            value={perfilPesquisa}
            label="Perfil"
            onChange={aoAlterarPerfilPesquisa}
          />
        </span>

        <ContainerPesquisa>
          {usuariosPesquisados.map(usuario => (
            <CardPesquisa key={usuario.id}>
              <div>
                <p>{usuario.name}</p>
                <p>{usuario.email}</p>
              </div>
              <div>
                <FiTrash2
                  onClick={() => {
                    handleInativarUsuario(usuario.email);
                  }}
                />
              </div>
            </CardPesquisa>
          ))}
        </ContainerPesquisa>
      </GroupBoxUsuario>

      <BotoesContainer>
        <BotaoSalvar onClick={aoSalvar}>
          <FiSave />
          Salvar
        </BotaoSalvar>

        <BotaoLimpar onClick={aoLimpar}>
          <FiRefreshCw />
          Limpar
        </BotaoLimpar>
      </BotoesContainer>
    </Container>
  );
};

export default Usuario;
