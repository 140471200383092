import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactElement,
  useMemo,
} from 'react';

import api from '../../services/api';

import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import ComboboxComFiltro from '../../components/autocomplete';
import { TextField } from '@material-ui/core';

import { useConfigs } from '../../context/ConfigsContext';
import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';
import { useNotifications } from '../../context/NotificationContext';
import { Link, useHistory } from 'react-router-dom';
import { resumeNome } from '../../utils/resumeNome';

import Dialog from '../../components/dialog';
import ConfirmationModal from '../../components/confirmationModal';

import Convenio from '../Convenio';
import Categoria from '../Categoria';
import Paciente from '../Paciente';
import Notificacoes from '../Notificações';
import Recorrencia from '../Recorrencia';
import Espera from '../Espera';
import Agendamento from '../Agendamento';

import {
  FiLogOut,
  FiPlusCircle,
  FiChevronLeft,
  FiChevronRight,
  FiUserPlus,
  FiClipboard,
  FiBookmark,
  FiTrash2,
  FiFilter,
  FiRefreshCw,
  FiBell,
  FiList,
  FiSlash,
  FiUsers,
} from 'react-icons/fi';

import semLogo from '../../assets/sem-logo.png';
import defaultAvatar from '../../assets/default-avatar.png';
import logoImg from '../../assets/mundo-aba-logo.png';

import {
  Container,
  AgendaContainer,
  AgendaColumn,
  Cabecalho,
  ConteudoCabecalho,
  Perfil,
  BotaoNovo,
  BotoesContainer,
  TabButton,
  FiltrosContainer,
  Calendario,
  VisoesLinha,
  LinhaCelular,
  MenuLinha,
  ControleCelularContainer,
  ControlDiv,
  BotoesControle,
  ListaEspera,
  Totalizador,
  PacienteEsperando,
  MenuIcon,
  RelogioIcon,
  RecorrenciaIcon,
  LinhaAgenda,
  HoraAgenda,
  EspacoAgenda,
  EspacoAgendaConteudo,
  CabecalhoColunaLateral,
  CalendarioColumn,
  ListaEsperaColumn,
  RecorrenciaColumn,
  PacienteEsperandoContainer,
  Carrossel,
  CarrouselBotao,
  CarrosselContainer,
  CarrosselItem,
  SetaEsquerdaIcon,
  SetaDireitaIcon,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  ContainerCadastros,
  BotaoMenu,
  ToggleCircle,
  ToggleContainer,
  ToggleText,
  CardBloqueado,
} from './styles';

import IListaEspera from '../../routes/vms/IListaEspera';
import ICategoria from '../../routes/vms/ICategoria';
import IConvenio from '../../routes/vms/IConvenio';
import IProfissional from '../../routes/vms/IProfissional';
import IAgendamento from '../../routes/vms/IAgendamento';
import { returnAxiosError } from '../../utils/returnAxiosError';
import { setTimeout } from 'timers';
import { useIndexedDB } from '../../context/IndexedDBContext';
import Bloqueio from '../Bloqueio';
import Perfis from '../Perfis';
import Usuario from '../Usuarios';

const Agenda: React.FC = () => {
  const refs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const { lerConfig } = useConfigs();
  const { user, signOut, verifyAccess } = useAuth();
  const { addToast } = useToast();
  const { notification_number, notifications, fetchNotifications } =
    useNotifications();
  const { gravarIndexedDB, lerIndexedDB, excluirIndexedDB } = useIndexedDB();

  const history = useHistory();

  const carrosselRef = useRef<HTMLDivElement>(null);
  const [horarios, setHorarios] = useState<string[]>([]);
  const [diasDesabilitados, setDiasDesabilitados] = useState<number[]>([]);
  const [diasHabilitados, setDiasHabilitados] = useState<number[]>([]);
  const [agendamentos, setAgendamentos] = useState<IAgendamento[]>([]);
  const [datePickerExpandido, setDatePickerExpandido] = useState<boolean>(true);
  const [listaEsperaExpandida, setListaEsperaExpandida] =
    useState<boolean>(false);
  const [recorrenciaExpandida, setRecorrenciaExpandida] =
    useState<boolean>(false);
  const [dataSelecionada, setDataSelecionada] = useState(new Date());
  const [logoDinamico, setLogoDinamico] = useState<string | undefined>(
    undefined,
  );
  const [isMobile, setIsMobile] = useState(false);
  const [menuSelecionado, setMenuSelecionado] = useState<string | undefined>(
    undefined,
  );
  const [profissionais, setProfissionais] = useState<IProfissional[]>([]);
  const [profissionaisSelecionados, setProfissionaisSelecionados] = useState<
    IProfissional[]
  >([]);
  const [listaEspera, setListaEspera] = useState<IListaEspera[]>([]);
  const [listaEsperaFiltro, setListaEsperaFiltro] = useState<IListaEspera[]>(
    [],
  );
  const [isEsperaOpen, setEsperaOpen] = useState(false);
  const [isAgendamentoOpen, setAgendamentoOpen] = useState(false);
  const [isBloqueioOpen, setBloqueioOpen] = useState(false);
  const [isRecorrenciaOpen, setRecorrenciaOpen] = useState(false);
  const [filtroVisivel, setFiltroVisivel] = useState(false);
  const [nomePesquisa, setNomePesquisa] = useState<string>('');
  const [convenioPesquisa, setConvenioPesquisa] = useState<string | null>(null);
  const [categoriaPesquisa, setCategoriaPesquisa] = useState<string | null>(
    null,
  );
  const [categorias, setCategorias] = useState<ICategoria[]>([]);
  const [convenios, setConvenios] = useState<IConvenio[]>([]);
  const [totalEspera, setTotalEspera] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [tipoAgendamentoOpen, setTipoAgendamentoOpen] = useState(false);
  const [esperaSelecionada, setEsperaSelecionada] = useState<
    IListaEspera | undefined
  >(undefined);
  const [hora, setHora] = useState('');
  const [profissional, setProfissional] = useState<string>('');
  const [selectedAgendamento, setSelectedAgendamento] = useState<
    IAgendamento | undefined
  >(undefined);
  const [bloquear, setBloquear] = useState(false);
  const [marcarTodos, setMarcarTodos] = useState<boolean>(false);

  const atualizaListaEspera = useCallback(async () => {
    await api.get<IListaEspera[]>('/espera/listaCompleta').then(response => {
      setListaEspera(response.data);
    });
  }, [setListaEspera]);

  const defineHorarios = useCallback(async () => {
    const horariosArray = await api.get<string[]>(
      `/empresas/horarios/${user.empresa.url_frontend}`,
    );

    setHorarios(horariosArray.data);
  }, [user]);

  const atualizaProfissionais = useCallback(async () => {
    const response = await api.get<IProfissional[]>('/users/profissionais', {
      params: {
        empresa_id: user.empresa_id,
      },
    });

    setProfissionais(response.data);
  }, [user.empresa_id]);

  const toggleMarcarTodos = useCallback(
    async (marcar: boolean) => {
      excluirIndexedDB(
        `${user.empresa.url_frontend}:profissionais-selecionados-usuario:${user.id}`,
      );

      if (marcar) {
        setProfissionaisSelecionados(profissionais);
        gravarIndexedDB(
          `${user.empresa.url_frontend}:profissionais-selecionados-usuario:${user.id}`,
          profissionais,
        );
      } else {
        setProfissionaisSelecionados([]);
      }

      setMarcarTodos(marcar);
    },
    [profissionais, excluirIndexedDB, gravarIndexedDB, user],
  );

  const atualizaDados = useCallback(async () => {
    const [categoriasResponse, conveniosResponse] = await Promise.all([
      api.get<ICategoria[]>('/categoria/lista'),
      api.get<IConvenio[]>('/convenio/lista'),
    ]);

    setCategorias(categoriasResponse.data);
    setConvenios(conveniosResponse.data);
  }, []);

  const agendamentosDia = useCallback(async () => {
    let profissionaisIds = profissionaisSelecionados
      .map(profissional => profissional.id)
      .join(',');

    const dataFormatada = `${dataSelecionada.getFullYear()}-${(
      dataSelecionada.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}-${dataSelecionada
      .getDate()
      .toString()
      .padStart(2, '0')}`;

    const response = await api.get<IAgendamento[]>(
      `/agendamentos/dia?data=${dataFormatada}&profissionaisIds=${profissionaisIds}`,
    );

    setAgendamentos(response.data);
  }, [dataSelecionada, profissionaisSelecionados]);

  const handleOpenEspera = () => setEsperaOpen(true);

  const handleCloseEspera = useCallback(() => {
    setEsperaOpen(false);

    atualizaListaEspera();
  }, [atualizaListaEspera]);

  const handleOpenAgendamento = useCallback(
    (agendamento: IAgendamento | undefined) => {
      if (bloquear || agendamento?.inutilizado) {
        setAgendamentoOpen(false);
        setBloqueioOpen(true);
      } else {
        setEsperaSelecionada(undefined);
        setBloqueioOpen(false);
        setAgendamentoOpen(true);
      }
    },
    [bloquear],
  );

  const handleCloseAgendamento = useCallback(() => {
    setAgendamentoOpen(false);

    setHora('');
    setProfissional('');
    setSelectedAgendamento(undefined);

    agendamentosDia();
    atualizaListaEspera();
  }, [agendamentosDia, atualizaListaEspera]);

  const handleCloseBloqueio = useCallback(() => {
    setBloqueioOpen(false);

    setHora('');
    setProfissional('');
    setSelectedAgendamento(undefined);

    agendamentosDia();
  }, [agendamentosDia]);

  const handleCloseRecorrencia = useCallback(() => {
    setRecorrenciaOpen(false);

    agendamentosDia();
    atualizaListaEspera();
  }, [agendamentosDia, atualizaListaEspera]);

  const calculaDiferencaEmDias = useCallback((dataInclusao: Date) => {
    const MILISSEGUNDOS_POR_DIA = 1000 * 60 * 60 * 24;

    const timestampInicial = new Date(dataInclusao).getTime();
    const timestampFinal = new Date().getTime();

    const diferencaEmMilissegundos = timestampFinal - timestampInicial;

    return Math.round(diferencaEmMilissegundos / MILISSEGUNDOS_POR_DIA);
  }, []);

  const handleExcluirEspera = useCallback(
    async (motivo: string) => {
      if (motivo === '') {
        addToast({
          type: 'error',
          title: 'Erro ao excluir',
          description: 'Motivo não informado',
        });

        return;
      }

      try {
        await api.post(`/espera/excluir/${esperaSelecionada?.id}`, {
          motivo: motivo.toUpperCase(),
        });
        atualizaListaEspera();
      } catch (error) {
        addToast(returnAxiosError(error));

        return;
      }

      setModalOpen(false);

      addToast({
        type: 'success',
        title: 'Removido',
        description: 'Paciente removido da fila de espera',
      });
    },
    [atualizaListaEspera, addToast, esperaSelecionada],
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return; // O item não foi solto em um lugar válido
      }

      const { source, destination } = result;

      const ordem_atual = listaEsperaFiltro[source.index].ordem;
      const ordem_nova = listaEsperaFiltro[destination.index].ordem;

      try {
        //Estou atualizando local pois quando pega da API para atualizar a lista tem um delay na animação do drag e drop
        const updatedList = [...listaEsperaFiltro];
        const [removed] = updatedList.splice(source.index, 1);
        updatedList.splice(destination.index, 0, removed);
        setListaEsperaFiltro(updatedList);

        await api.patch('/espera/reordenar/', { ordem_atual, ordem_nova });
        atualizaListaEspera();
      } catch (error) {
        atualizaListaEspera();
        addToast(returnAxiosError(error));
      }
    },
    [addToast, atualizaListaEspera, listaEsperaFiltro],
  );

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();

    if (!carrosselRef.current) return;

    const { current: carrossel } = carrosselRef;

    carrossel.style.scrollBehavior = 'auto';

    const startX = e.pageX - carrossel.offsetLeft;
    const scrollLeft = carrossel.scrollLeft;

    const onMouseMove = (e: MouseEvent) => {
      const x = e.pageX - carrossel.offsetLeft;
      const scroll = x - startX;
      carrossel.scrollLeft = scrollLeft - scroll;
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);

      carrossel.style.scrollBehavior = 'smooth';
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }, []);

  const scrollLeft = () => {
    if (carrosselRef.current) {
      const { current: carrossel } = carrosselRef;

      const carrosselWidth = carrossel.clientWidth;

      const itemWidth = carrossel.firstElementChild?.clientWidth || 0;

      const itemsPerPage = Math.floor(carrosselWidth / itemWidth);

      carrossel.scrollLeft -= itemsPerPage * itemWidth;
    }
  };

  const scrollRight = () => {
    if (carrosselRef.current) {
      const { current: carrossel } = carrosselRef;

      const carrosselWidth = carrossel.clientWidth;

      const itemWidth = carrossel.firstElementChild?.clientWidth || 0;

      const itemsPerPage = Math.floor(carrosselWidth / itemWidth);

      carrossel.scrollLeft += itemsPerPage * itemWidth;
    }
  };

  const renderizaTelasMenu = useCallback((): ReactElement => {
    let telaCarregar: ReactElement = <></>;

    switch (menuSelecionado) {
      case 'CONVENIO':
        telaCarregar = <Convenio />;
        break;
      case 'CATEGORIA':
        telaCarregar = <Categoria />;
        break;
      case 'PACIENTE':
        telaCarregar = <Paciente />;
        break;
      case 'NOTIFICACOES':
        telaCarregar = <Notificacoes notificacoesPar={notifications} />;
        break;
      case 'PERFIL':
        telaCarregar = <Perfis />;
        break;
      case 'USUARIO':
        telaCarregar = <Usuario />;
        break;
    }

    return telaCarregar;
  }, [menuSelecionado, notifications]);

  const scrollToCurrentTime = useCallback(() => {
    const agora = new Date();
    const horaAtual =
      agora.getHours().toString().padStart(2, '0') +
      ':' +
      agora.getMinutes().toString().padStart(2, '0');

    let horarioParaScrollar = horarios.find(h => h === horaAtual);

    if (!horarioParaScrollar) {
      horarioParaScrollar =
        [...horarios].reverse().find(h => h <= horaAtual) || horarios[0];
    }

    refs.current[horarioParaScrollar]?.scrollIntoView();
  }, [horarios]);

  const toggleSelecionado = useCallback(
    async (profissional: IProfissional) => {
      if (isMobile) {
        setProfissionaisSelecionados([profissional]);
      } else {
        const profissionaisIndexedDB: IProfissional[] | null =
          await lerIndexedDB(
            `${user.empresa.url_frontend}:profissionais-selecionados-usuario:${user.id}`,
          );

        if (profissionaisSelecionados.some(p => p.id === profissional.id)) {
          // Se o profissional já está selecionado, remove da lista e do indexed db
          setProfissionaisSelecionados(
            profissionaisSelecionados.filter(
              profissionalFiltro => profissionalFiltro.id !== profissional.id,
            ),
          );

          if (profissionaisIndexedDB) {
            excluirIndexedDB(
              `${user.empresa.url_frontend}:profissionais-selecionados-usuario:${user.id}`,
            );

            gravarIndexedDB(
              `${user.empresa.url_frontend}:profissionais-selecionados-usuario:${user.id}`,
              profissionaisSelecionados.filter(
                profissionalFiltro => profissionalFiltro.id !== profissional.id,
              ),
            );
          }
        } else {
          // Se não estiver selecionado, adicionar à lista e ao indexed db
          setProfissionaisSelecionados([
            ...profissionaisSelecionados,
            profissional,
          ]);

          if (profissionaisIndexedDB) {
            gravarIndexedDB(
              `${user.empresa.url_frontend}:profissionais-selecionados-usuario:${user.id}`,
              [...profissionaisIndexedDB, profissional],
            );
          } else {
            gravarIndexedDB(
              `${user.empresa.url_frontend}:profissionais-selecionados-usuario:${user.id}`,
              [profissional],
            );
          }
        }
      }

      setTimeout(() => {
        scrollToCurrentTime();
      }, 100);
    },
    [
      isMobile,
      scrollToCurrentTime,
      profissionaisSelecionados,
      gravarIndexedDB,
      lerIndexedDB,
      excluirIndexedDB,
      user,
    ],
  );

  const toggleListaExpandida = useCallback(() => {
    setListaEsperaExpandida(!listaEsperaExpandida);

    if (isMobile) {
      setDatePickerExpandido(false);
    }
  }, [isMobile, listaEsperaExpandida]);

  const toggleRecorrenciaExpandida = useCallback(() => {
    setRecorrenciaExpandida(!recorrenciaExpandida);

    agendamentosDia();

    // setDatePickerExpandido(false);
    // setListaEsperaExpandida(false);
  }, [recorrenciaExpandida, agendamentosDia]);

  const toggleDatePickerExpandido = useCallback(() => {
    setDatePickerExpandido(!datePickerExpandido);

    if (isMobile) {
      setListaEsperaExpandida(false);
    }
  }, [isMobile, datePickerExpandido]);

  const estaDesabilitado = (date: Date) => {
    const diaSemana = date.getDay();
    return diasDesabilitados.some(desabilitado => desabilitado === diaSemana);
  };

  const vaiParaDiaAnterior = () => {
    let diaAnterior = new Date(dataSelecionada);
    diaAnterior.setDate(dataSelecionada.getDate() - 1);

    while (estaDesabilitado(diaAnterior)) {
      diaAnterior.setDate(diaAnterior.getDate() - 1);
    }

    setDataSelecionada(diaAnterior);
  };

  const vaiParaProximoDia = () => {
    let proximoDia = new Date(dataSelecionada);
    proximoDia.setDate(dataSelecionada.getDate() + 1);

    while (estaDesabilitado(proximoDia)) {
      proximoDia.setDate(proximoDia.getDate() + 1);
    }

    setDataSelecionada(proximoDia);
  };

  const vaiParaHoje = () => {
    let hoje = new Date();

    while (estaDesabilitado(hoje)) {
      hoje.setDate(hoje.getDate() + 1);
    }

    setDataSelecionada(hoje);
  };

  const handleDateChange = useCallback(
    (day: Date, modifiers: DayModifiers) => {
      if (modifiers.available && !modifiers.disabled) {
        setDataSelecionada(day);
      }

      if (isMobile) {
        setDatePickerExpandido(false);
      }

      setMenuSelecionado(undefined);
      setRecorrenciaExpandida(false);
    },
    [isMobile],
  );

  const handleMenuClick = useCallback(
    (nomeMenu: string) => {
      if (menuSelecionado === nomeMenu) {
        setMenuSelecionado(undefined);
      } else {
        setMenuSelecionado(nomeMenu);
      }

      setRecorrenciaExpandida(false);

      if (isMobile) {
        setDatePickerExpandido(false);
      }

      setTimeout(() => {
        scrollToCurrentTime();
      }, 100);
    },
    [isMobile, menuSelecionado, scrollToCurrentTime],
  );

  const abrirProfile = useCallback(() => {
    history.push('/profile');
  }, [history]);

  const setProfissionaisSelecionadosIndexedDB = useCallback(async () => {
    const profissionaisIndexedDB: IProfissional[] | null = await lerIndexedDB(
      `${user.empresa.url_frontend}:profissionais-selecionados-usuario:${user.id}`,
    );

    if (profissionaisIndexedDB) {
      setProfissionaisSelecionados(profissionaisIndexedDB);
    }
  }, [lerIndexedDB, user]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let configLogo: string | undefined = lerConfig('LOGO');
    if (configLogo && configLogo !== '') {
      api
        .get<string>('/empresas/configs-caminho-arquivo', {
          params: {
            arquivo: configLogo,
          },
        })
        .then(response => {
          setLogoDinamico(response.data);
        });
    } else {
      configLogo = undefined;
    }
  }, [lerConfig]);

  useEffect(() => {
    defineHorarios();

    const stringDiasDesabilitados = lerConfig('DIAS QUE NÃO TRABALHA');
    const diasSemana = [0, 1, 2, 3, 4, 5, 6];
    const diasDesabilitados = stringDiasDesabilitados
      .split(',')
      .map(num => Number(num.trim()));

    setDiasDesabilitados(diasDesabilitados);
    setDiasHabilitados(
      diasSemana.filter((_, index) => !diasDesabilitados.includes(index)),
    );
  }, [defineHorarios, lerConfig]);

  useEffect(() => {
    atualizaDados();
  }, [atualizaDados]);

  useEffect(() => {
    atualizaProfissionais();
  }, [atualizaProfissionais]);

  useEffect(() => {
    scrollToCurrentTime();
  }, [scrollToCurrentTime]);

  const limparCamposEspera = useCallback(() => {
    setNomePesquisa('');
    setConvenioPesquisa(null);
    setCategoriaPesquisa(null);

    atualizaListaEspera();
  }, [atualizaListaEspera]);

  useEffect(() => {
    atualizaListaEspera();
  }, [atualizaListaEspera]);

  useEffect(() => {
    agendamentosDia();
  }, [agendamentosDia]);

  useEffect(() => {
    setProfissionaisSelecionadosIndexedDB();
  }, [setProfissionaisSelecionadosIndexedDB]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const aoAlterarConvenioPesquisa = useCallback((id: string | null) => {
    setConvenioPesquisa(id);
  }, []);

  const aoAlterarNomePesquisa = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNomePesquisa(event.target.value);
    },
    [],
  );

  const pesquisarEspera = useCallback(() => {
    let listaFiltrar = listaEspera;

    if (nomePesquisa) {
      listaFiltrar = listaFiltrar.filter(espera =>
        espera.paciente.nome.toUpperCase().includes(nomePesquisa.toUpperCase()),
      );
    }

    if (convenioPesquisa) {
      listaFiltrar = listaFiltrar.filter(
        espera => espera.paciente.convenio_id === convenioPesquisa,
      );
    }

    if (categoriaPesquisa) {
      listaFiltrar = listaFiltrar.filter(espera =>
        espera.categoria.id
          .toUpperCase()
          .includes(categoriaPesquisa.toUpperCase()),
      );
    }

    setListaEsperaFiltro(listaFiltrar);
    setTotalEspera(listaFiltrar.length);
  }, [nomePesquisa, convenioPesquisa, categoriaPesquisa, listaEspera]);

  useEffect(() => {
    pesquisarEspera();
  }, [pesquisarEspera]);

  useEffect(() => {
    const profissionaisIds = profissionais.map(profissional => profissional.id);
    const profissionaisSelecionadosIds = profissionaisSelecionados.map(
      profissional => profissional.id,
    );

    if (profissionaisIds.length !== profissionaisSelecionadosIds.length) {
      setMarcarTodos(false);
    } else {
      setMarcarTodos(
        profissionaisIds.every(id => profissionaisSelecionadosIds.includes(id)),
      );
    }
  }, [profissionais, profissionaisSelecionados]);

  const aoAlterarCategoriaPesquisa = useCallback((id: string | null) => {
    setCategoriaPesquisa(id);
  }, []);

  const horariosAgendados = useMemo(() => {
    return horarios.slice(0, -1).map(horario => {
      return {
        horario,
        agendamento: agendamentos.find(
          agendamento =>
            agendamento.profissional_id === profissionaisSelecionados[0].id &&
            agendamento.hora === horario + ':00',
        ),
      };
    });
  }, [horarios, agendamentos, profissionaisSelecionados]);

  const handleAgendaClick = useCallback(
    (agendamento: IAgendamento | undefined, horario: string) => {
      setSelectedAgendamento(agendamento);
      setHora(horario);
      setProfissional(profissionaisSelecionados[0].id);
      handleOpenAgendamento(agendamento);
    },
    [
      setSelectedAgendamento,
      setHora,
      setProfissional,
      profissionaisSelecionados,
      handleOpenAgendamento,
    ],
  );

  return (
    <Container>
      <Cabecalho>
        <ConteudoCabecalho>
          <Link to="/home">
            <div>
              <img src={logoDinamico ? logoDinamico : logoImg} alt="Logo" />
            </div>
          </Link>

          {user.empresa.utiliza_login === 'S' && (
            <Perfil>
              {/* <ul>
                <li>
                  <button type="button" onClick={abrirProfile}>
                    <FiUser />
                    <span>Perfil</span>
                  </button>
                </li>
                <li>
                  <button type="button" onClick={signOut}>
                    <FiPower />
                    <span>Sair</span>
                  </button>
                </li>
              </ul> */}

              <img
                src={!user.avatar_url ? defaultAvatar : user.avatar_url}
                alt={user.name}
                onClick={abrirProfile}
                title={resumeNome(user.name)}
              />

              <div>
                {notification_number > 0 && <span>{notification_number}</span>}
                <button
                  className={
                    menuSelecionado === 'NOTIFICACOES' ? 'botaoSelecionado' : ''
                  }
                  type="button"
                  title="Notificações"
                  onClick={() => {
                    handleMenuClick('NOTIFICACOES');
                  }}
                >
                  <FiBell />
                </button>
              </div>

              <button type="button" title="Sair" onClick={signOut}>
                <FiLogOut />
              </button>
            </Perfil>
          )}
        </ConteudoCabecalho>
      </Cabecalho>

      <AgendaContainer>
        <CalendarioColumn expanded={datePickerExpandido}>
          {datePickerExpandido ? (
            <>
              <CabecalhoColunaLateral>
                <MenuIcon
                  onClick={() => {
                    toggleDatePickerExpandido();
                  }}
                />
              </CabecalhoColunaLateral>

              <Calendario>
                <DayPicker
                  weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                  disabledDays={[{ daysOfWeek: diasDesabilitados }]}
                  selectedDays={dataSelecionada}
                  month={dataSelecionada}
                  fixedWeeks
                  modifiers={{
                    available: { daysOfWeek: diasHabilitados },
                  }}
                  onDayClick={handleDateChange}
                  months={[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro',
                  ]}
                />
              </Calendario>

              <BotaoMenu
                isActive={menuSelecionado === 'CONVENIO'}
                onClick={() => {
                  handleMenuClick('CONVENIO');
                }}
              >
                <FiBookmark /> Convenio
              </BotaoMenu>

              <BotaoMenu
                isActive={menuSelecionado === 'CATEGORIA'}
                onClick={() => {
                  handleMenuClick('CATEGORIA');
                }}
              >
                <FiClipboard /> Categoria
              </BotaoMenu>

              {verifyAccess('VISUALIZAR CADASTRO DO PACIENTE') && (
                <BotaoMenu
                  isActive={menuSelecionado === 'PACIENTE'}
                  onClick={() => {
                    handleMenuClick('PACIENTE');
                  }}
                >
                  <FiUserPlus /> Paciente
                </BotaoMenu>
              )}

              <BotaoMenu
                isActive={menuSelecionado === 'USUARIO'}
                onClick={() => {
                  handleMenuClick('USUARIO');
                }}
              >
                <FiUsers /> Usuários
              </BotaoMenu>

              {verifyAccess('VISUALIZAR CADASTRO DE PERFIL') && (
                <BotaoMenu
                  isActive={menuSelecionado === 'PERFIL'}
                  onClick={() => {
                    handleMenuClick('PERFIL');
                  }}
                >
                  <FiList /> Perfil
                </BotaoMenu>
              )}
            </>
          ) : (
            <MenuIcon
              onClick={() => {
                toggleDatePickerExpandido();
              }}
            />
          )}
        </CalendarioColumn>

        {verifyAccess('VISUALIZAR LISTA DE ESPERA') && (
          <ListaEsperaColumn expanded={listaEsperaExpandida}>
            {listaEsperaExpandida ? (
              <>
                <CabecalhoColunaLateral>
                  <RelogioIcon
                    onClick={() => {
                      toggleListaExpandida();
                    }}
                  />
                </CabecalhoColunaLateral>

                <p>Lista de Espera</p>

                <DragDropContext onDragEnd={onDragEnd}>
                  <ListaEspera>
                    <BotoesContainer>
                      <BotaoNovo>
                        <button onClick={handleOpenEspera}>
                          <div>
                            <FiPlusCircle />
                            <span>Espera</span>
                          </div>
                        </button>
                      </BotaoNovo>

                      <TabButton
                        onClick={() => {
                          setFiltroVisivel(!filtroVisivel);
                        }}
                      >
                        <FiFilter />
                      </TabButton>

                      <TabButton
                        onClick={() => {
                          limparCamposEspera();
                        }}
                      >
                        <FiRefreshCw />
                      </TabButton>
                    </BotoesContainer>

                    {filtroVisivel === true ? (
                      <FiltrosContainer>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          id="pacientePesq"
                          label="Paciente"
                          name="pacientePesq"
                          value={nomePesquisa}
                          onChange={aoAlterarNomePesquisa}
                          style={{ width: '100%' }}
                        />

                        <ComboboxComFiltro
                          opcoes={convenios}
                          value={convenioPesquisa}
                          label="Convênio"
                          onChange={aoAlterarConvenioPesquisa}
                        />

                        <ComboboxComFiltro
                          opcoes={categorias}
                          value={categoriaPesquisa}
                          label="Categoria"
                          onChange={aoAlterarCategoriaPesquisa}
                        />
                      </FiltrosContainer>
                    ) : (
                      <></>
                    )}

                    <Totalizador>
                      <p>{`Total em espera: ${totalEspera}`}</p>
                    </Totalizador>

                    <Droppable droppableId="lista-espera">
                      {provided => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {listaEsperaFiltro.length > 0 ? (
                            listaEsperaFiltro.map((espera, index) => (
                              <div key={espera.id}>
                                <Draggable
                                  key={espera.id}
                                  draggableId={espera.id}
                                  index={index}
                                >
                                  {provided => (
                                    <PacienteEsperandoContainer
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <p>{espera.ordem + 'º'}</p>

                                      <PacienteEsperando
                                        onClick={() => {
                                          setTipoAgendamentoOpen(true);
                                          setEsperaSelecionada(espera);
                                        }}
                                        title={
                                          espera.categoria.nome +
                                          ' - DIAS ESPERA: ' +
                                          calculaDiferencaEmDias(
                                            espera.data_inclusao,
                                          )
                                        }
                                        data-tip={
                                          espera.categoria.nome +
                                          ' - DIAS ESPERA: '
                                        }
                                        categoryColor={espera.categoria.cor}
                                      >
                                        {resumeNome(espera.paciente.nome)}
                                        <img
                                          src={
                                            espera.paciente.convenio?.logo_url
                                              ? espera.paciente.convenio
                                                  ?.logo_url
                                              : semLogo
                                          }
                                          alt="Logo Convênio"
                                        />
                                      </PacienteEsperando>

                                      <FiTrash2
                                        onClick={() => {
                                          setEsperaSelecionada(espera);
                                          setModalOpen(true);
                                        }}
                                      />
                                    </PacienteEsperandoContainer>
                                  )}
                                </Draggable>
                              </div>
                            ))
                          ) : (
                            <div>
                              <p>
                                Nenhum paciente na lista de espera no momento
                              </p>
                            </div>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </ListaEspera>
                </DragDropContext>
              </>
            ) : (
              <RelogioIcon
                onClick={() => {
                  toggleListaExpandida();
                }}
              />
            )}
          </ListaEsperaColumn>
        )}

        {isMobile === false && (
          <RecorrenciaColumn expanded={recorrenciaExpandida}>
            {recorrenciaExpandida ? (
              <>
                <CabecalhoColunaLateral>
                  <RecorrenciaIcon
                    onClick={() => {
                      toggleRecorrenciaExpandida();
                    }}
                  />
                </CabecalhoColunaLateral>

                <Recorrencia esperaSelecionada={undefined} externo={false} />
              </>
            ) : (
              <RecorrenciaIcon
                onClick={() => {
                  toggleRecorrenciaExpandida();
                }}
              />
            )}
          </RecorrenciaColumn>
        )}

        <AgendaColumn hidden={recorrenciaExpandida}>
          {menuSelecionado ? (
            <ContainerCadastros>{renderizaTelasMenu()}</ContainerCadastros>
          ) : (
            <>
              <VisoesLinha active={false}>
                <>
                  <CarrosselContainer>
                    <CarrouselBotao className="prev" onClick={scrollLeft}>
                      <SetaEsquerdaIcon />
                    </CarrouselBotao>

                    <Carrossel ref={carrosselRef} onMouseDown={handleMouseDown}>
                      {profissionais
                        .sort(
                          (a, b) =>
                            +profissionaisSelecionados.some(
                              p => p.id === b.id,
                            ) -
                            +profissionaisSelecionados.some(p => p.id === a.id),
                        )
                        .map(profissional => (
                          <CarrosselItem
                            key={profissional.id}
                            onClick={() => {
                              toggleSelecionado(profissional);
                            }}
                            selected={profissionaisSelecionados.some(
                              p => p.id === profissional.id,
                            )}
                          >
                            <img
                              src={
                                profissional.avatar_url
                                  ? profissional.avatar_url
                                  : defaultAvatar
                              }
                              alt="avatar-profissional"
                            />
                            <p>{resumeNome(profissional.name)}</p>
                          </CarrosselItem>
                        ))}
                    </Carrossel>

                    <CarrouselBotao className="next" onClick={scrollRight}>
                      <SetaDireitaIcon />
                    </CarrouselBotao>
                  </CarrosselContainer>

                  {isMobile || (
                    <label>
                      <input
                        type="checkbox"
                        checked={marcarTodos}
                        onChange={() => {
                          toggleMarcarTodos(!marcarTodos);
                        }}
                      />
                      Todos
                    </label>
                  )}
                </>
              </VisoesLinha>

              <LinhaCelular>
                <p>
                  {dataSelecionada.toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </p>

                <ControleCelularContainer>
                  <SetaEsquerdaIcon onClick={vaiParaDiaAnterior} />
                  <button onClick={vaiParaHoje}>Voltar para hoje</button>
                  <SetaDireitaIcon onClick={vaiParaProximoDia} />
                </ControleCelularContainer>
              </LinhaCelular>

              <MenuLinha>
                <ToggleContainer
                  inutilizado={bloquear}
                  onClick={() => {
                    setBloquear(!bloquear);
                  }}
                >
                  <ToggleCircle inutilizado={bloquear} />
                  <ToggleText inutilizado={bloquear}>
                    {bloquear ? 'Bloquear' : 'Agendar'}
                  </ToggleText>
                </ToggleContainer>

                <ControlDiv>
                  <BotoesControle>
                    <button
                      className="previewButton"
                      onClick={vaiParaDiaAnterior}
                    >
                      <FiChevronLeft />
                    </button>

                    <button className="todayButton" onClick={vaiParaHoje}>
                      Hoje
                    </button>

                    <button className="nextButton" onClick={vaiParaProximoDia}>
                      <FiChevronRight />
                    </button>
                  </BotoesControle>

                  <p>
                    {dataSelecionada.toLocaleDateString('pt-BR', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}
                  </p>
                </ControlDiv>
              </MenuLinha>
              {menuSelecionado === undefined ? (
                <LinhaAgenda>
                  {profissionaisSelecionados.length === 1 ? (
                    <>
                      {horariosAgendados.map(({ horario, agendamento }) => {
                        return (
                          <div key={horario}>
                            <HoraAgenda>{horario}</HoraAgenda>
                            <EspacoAgenda
                              onClick={() =>
                                handleAgendaClick(agendamento, horario)
                              }
                              key={horario}
                              ref={el => (refs.current[horario] = el)}
                              agendado={!!agendamento}
                              className={
                                agendamento?.inutilizado
                                  ? 'inutilizado'
                                  : 'horario'
                              }
                              cor={agendamento?.categoria?.cor}
                            >
                              {agendamento ? (
                                <EspacoAgendaConteudo
                                  title={agendamento.observacoes}
                                >
                                  {agendamento.inutilizado ? (
                                    <div>
                                      <p>BLOQUEADO</p>
                                      <p>{agendamento.categoria?.nome}</p>

                                      <CardBloqueado>
                                        <FiSlash />
                                      </CardBloqueado>
                                    </div>
                                  ) : (
                                    <>
                                      <div>
                                        <p>{agendamento.paciente?.nome}</p>
                                        <p>{agendamento.categoria?.nome}</p>
                                      </div>
                                      <span>
                                        <img
                                          src={
                                            agendamento.paciente?.convenio
                                              ?.logo_url
                                              ? agendamento.paciente?.convenio
                                                  ?.logo_url
                                              : semLogo
                                          }
                                          alt="Logo Convênio"
                                        />
                                        <p>
                                          {agendamento.paciente?.convenio?.nome
                                            ? agendamento.paciente?.convenio
                                                ?.nome
                                            : ''}
                                        </p>
                                      </span>
                                    </>
                                  )}
                                </EspacoAgendaConteudo>
                              ) : (
                                ''
                              )}
                            </EspacoAgenda>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <Table>
                      <thead>
                        <TableRow
                          style={{
                            position: 'sticky',
                            top: '-11px',
                            left: 0,
                            zIndex: 2,
                            background: '#fff',
                          }}
                        >
                          <TableHeader>Horários</TableHeader>
                          {profissionaisSelecionados.map(profissional => (
                            <TableHeader key={profissional.id}>
                              {resumeNome(profissional.name)}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </thead>
                      <tbody>
                        {horarios.slice(0, -1).map((horario, index) => (
                          <TableRow key={horario}>
                            <TableCell
                              style={{
                                position: 'sticky',
                                left: '-11px',
                                zIndex: 1,
                                backgroundColor:
                                  index % 2 === 0 ? '#ffffff' : '#f9f9f9',
                              }}
                            >
                              {horario}
                            </TableCell>
                            {profissionaisSelecionados.map(profissional => {
                              const horarioAgendado = agendamentos.find(
                                agendamento =>
                                  agendamento.profissional_id ===
                                    profissional.id &&
                                  agendamento.hora === horario + ':00',
                              );

                              return (
                                <TableCell
                                  key={profissional.id}
                                  style={{
                                    minWidth: '325px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setSelectedAgendamento(horarioAgendado);
                                    setHora(horario);
                                    setProfissional(profissional.id);
                                    handleOpenAgendamento(horarioAgendado);
                                  }}
                                >
                                  {horarioAgendado ? (
                                    <EspacoAgenda
                                      key={horario}
                                      ref={el => (refs.current[horario] = el)}
                                      agendado={!!horarioAgendado}
                                      cor={horarioAgendado?.categoria?.cor}
                                      title={horarioAgendado.observacoes}
                                      className={
                                        horarioAgendado?.inutilizado
                                          ? 'inutilizado'
                                          : 'horario'
                                      }
                                    >
                                      <EspacoAgendaConteudo>
                                        {horarioAgendado.inutilizado ? (
                                          <div>
                                            <p>BLOQUEADO</p>
                                            <p>
                                              {horarioAgendado.categoria?.nome}
                                            </p>
                                            <CardBloqueado>
                                              <FiSlash />
                                            </CardBloqueado>
                                          </div>
                                        ) : (
                                          <>
                                            <div>
                                              <p>
                                                {resumeNome(
                                                  horarioAgendado.paciente
                                                    ?.nome,
                                                )}
                                              </p>
                                              <p>
                                                {
                                                  horarioAgendado.categoria
                                                    ?.nome
                                                }
                                              </p>
                                            </div>
                                            <span>
                                              <img
                                                src={
                                                  horarioAgendado.paciente
                                                    ?.convenio?.logo_url
                                                    ? horarioAgendado.paciente
                                                        ?.convenio?.logo_url
                                                    : semLogo
                                                }
                                                alt="Logo Convênio"
                                              />
                                              <p>
                                                {horarioAgendado.paciente
                                                  ?.convenio?.nome
                                                  ? horarioAgendado.paciente
                                                      ?.convenio?.nome
                                                  : ''}
                                              </p>
                                            </span>
                                          </>
                                        )}
                                      </EspacoAgendaConteudo>
                                    </EspacoAgenda>
                                  ) : (
                                    ''
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </LinhaAgenda>
              ) : (
                <Recorrencia externo={false} />
              )}
            </>
          )}
        </AgendaColumn>
      </AgendaContainer>

      <Dialog isOpen={isAgendamentoOpen} onClose={handleCloseAgendamento}>
        <Agendamento
          onClose={handleCloseAgendamento}
          agendamentoParametro={selectedAgendamento}
          esperaParametro={esperaSelecionada}
          horaParametro={hora}
          profissionalParametro={profissional}
          dataParametro={dataSelecionada}
        />
      </Dialog>

      <Dialog isOpen={isBloqueioOpen} onClose={handleCloseBloqueio}>
        <Bloqueio
          bloqueioParametro={selectedAgendamento}
          dataParametro={dataSelecionada}
          horaParametro={hora}
          profissionalParametro={profissional}
          onClose={handleCloseBloqueio}
        />
      </Dialog>

      <Dialog isOpen={isRecorrenciaOpen} onClose={handleCloseRecorrencia}>
        <Recorrencia
          esperaSelecionada={esperaSelecionada}
          onClose={handleCloseRecorrencia}
          externo
        />
      </Dialog>

      <Dialog isOpen={isEsperaOpen} onClose={handleCloseEspera}>
        <Espera onClose={handleCloseEspera} />
      </Dialog>

      <ConfirmationModal
        open={modalOpen}
        title="Confirmar Exclusão"
        message="Informe o motivo para excluir esse item"
        exigeMotivo={true}
        onConfirm={motivo => {
          handleExcluirEspera(motivo);
        }}
        onCancel={() => setModalOpen(false)}
      />

      <ConfirmationModal
        open={tipoAgendamentoOpen}
        title="Tipo de Agendamento"
        message="Selecione o tipo de agendamento para prosseguir"
        exigeMotivo={false}
        textoCancelar="Único"
        textoConfirmar="Recorrente"
        onConfirm={() => {
          setTipoAgendamentoOpen(false);
          setRecorrenciaOpen(true);
        }}
        onCancel={() => {
          setTipoAgendamentoOpen(false);
          setAgendamentoOpen(true);
        }}
        onClose={() => {
          setTipoAgendamentoOpen(false);
        }}
        esperaParametro={esperaSelecionada}
      />
    </Container>
  );
};

export default Agenda;
